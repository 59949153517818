import React, { HTMLAttributes, PropsWithChildren } from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import CommentIcon from "components/Icons/Comment";
import { getPersonFullName } from "utils/person";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";

import useStyles from "./styles";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  bold?: boolean;
  trainerInfo: racehorse360.ITrainer;
  onCommentIconClick?: (e: React.ChangeEvent<HTMLElement>) => void;
  shouldDisplayComments?: boolean;
}

const TrainerCell = (props: PropsWithChildren<IProps>) => {
  const {
    bold,
    trainerInfo,
    onCommentIconClick,
    shouldDisplayComments = false,
    ...restProps
  } = props;

  const classes = useStyles();

  return (
    <TableCellWrapper bold={bold} {...restProps}>
      <>
        <div className={classes.trainerName}>
          {getPersonFullName(trainerInfo)}
        </div>
        {shouldDisplayComments && (
          <CommentIcon
            className={classes.trainerComment}
            onClick={onCommentIconClick}
          />
        )}
      </>
    </TableCellWrapper>
  );
};

export default React.memo(TrainerCell);
