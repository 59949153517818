import React, { useState } from "react";

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
import CloseIcon from "@material-ui/icons/Close";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { getPersonFullName } from "utils/person";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import WorkoutArticle from "./WorkoutArticle";

import useStyles from "./styles";

const MAX_WORKOUTS_COUNT = 10;

interface IProps {
  horse: racehorse360.IHorse;
  sourceDate: string;
}

const WorkoutHistoryModal = (props: IProps) => {
  const { horse, sourceDate } = props;

  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { useListWorkoutRequests } = useRacehorse360Api();

  const dateFilter = {
    range: {
      absolute: {
        before: sourceDate
      }
    }
  };

  const { isLoading, data } = useListWorkoutRequests(
    {
      query: {
        horseIds: [horse.id],
        date: dateFilter,
        decisionDate: dateFilter,
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_DENIED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REJECTED
        ]
      },
      getOptions: {
        select: [
          "date",
          "flag.priority",
          "workoutExam.result",
          "workoutExam.status",
          "workoutExam.type",
          "workoutExam.completedDate",
          "workoutExam.updatedOn",
          "workoutExam.riskLevel",
          "examReasonNote.notedBy",
          "examReasonNote.notedOn",
          "examReasonNote.body",
          "status",
          "updatedOn"
        ],
        orderBy: ["date desc"]
      },
      pagingOptions: {
        maxResults: MAX_WORKOUTS_COUNT
      }
    },
    {
      enabled: isModalOpen
    }
  );

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} className={classes.historyButton}>
        <HistoryIcon />
      </IconButton>
      {isModalOpen && (
        <Dialog
          classes={{
            paper: classes.dialogPaper
          }}
          open={isModalOpen}
          onClose={handleClose}
        >
          {isLoading && <Loader overlay />}
          <DialogTitle className={classes.dialogTitle}>
            Exam Reason Note &<br /> Flag History
            <IconButton
              color="primary"
              onClick={handleClose}
              size={"small"}
              classes={{
                root: classes.dialogCloseButton
              }}
            >
              <CloseIcon className={classes.dialogCloseButtonIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.historyContentHeader}>
              <div className={classes.horseName}>
                {horse.name.toUpperCase()}
              </div>
              <div className={classes.trainerName}>
                {getPersonFullName(horse.trainer)}
              </div>
            </div>
            {!isLoading &&
              data.workoutRequests.map(workoutRequest => (
                <WorkoutArticle
                  workoutRequest={workoutRequest}
                  key={workoutRequest.id}
                />
              ))}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default WorkoutHistoryModal;
