import React from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { racehorse360 } from "@tsg/1st-grpc-web";

import FlagPriorityIndicator from "components/VetExamsTable/TableRow/RowHeader/FlagPriorityIndicator";
import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import { horseExamResultsText } from "../helper";
import useStyles from "./styles";

interface IProps {
  workoutRequest: racehorse360.IWorkoutRequest;
}

const WorkoutArticle = (props: IProps) => {
  const { date, flag, status, workoutExam, examReasonNote, updatedOn } =
    props.workoutRequest;

  const isExamInProgress =
    workoutExam?.status ===
    racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_IN_PROGRESS;

  const examResult =
    workoutExam?.result && !isExamInProgress
      ? `- ${
          horseExamResultsText[racehorse360.HorseExamResult[workoutExam.result]]
        }`
      : "";

  const classes = useStyles();

  const renderWorkoutExam = () => {
    return workoutExam.type ===
      racehorse360.HorseExamType.HORSE_EXAM_TYPE_QUICK_PASS ? (
      <div>
        {`${format(
          parseISO(workoutExam.completedDate),
          "MM/dd/yy"
        )} - Quick Passed`}
      </div>
    ) : (
      <div>
        {`Exam ${format(
          parseISO(workoutExam.completedDate || workoutExam.updatedOn),
          "MM/dd/yy"
        )} ${examResult}`}
      </div>
    );
  };

  return (
    <article className={classes.article}>
      <div>
        Workout {format(parseISO(date), "MM/dd/yy")}
        {racehorse360.WorkoutRequestStatus[status] ===
          "WORKOUT_REQUEST_STATUS_REJECTED" && (
          <div>{format(parseISO(updatedOn), "MM/dd/yy")} - Rejected</div>
        )}
        {workoutExam && renderWorkoutExam()}
      </div>
      {flag && (
        <div className={classes.flagged}>
          <span className={classes.flaggedTitle}>Flagged</span>
          <FlagPriorityIndicator priority={flag.priority} />
        </div>
      )}
      {examReasonNote && (
        <div>
          <div>
            <span className={classes.commentCreator}>
              {`${getPersonFullName(examReasonNote.notedBy, "%f %m %L")} `}
            </span>
            <span className={classes.createDateTime}>
              {transformViewDate(
                examReasonNote.notedOn,
                null,
                "MM/dd/yy h:mm a"
              )}
            </span>
          </div>
          <div className={classes.examNotesContainerBody}>
            {examReasonNote.body}
          </div>
        </div>
      )}
    </article>
  );
};

export default WorkoutArticle;
