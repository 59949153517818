import React, { PropsWithChildren, SyntheticEvent } from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";

import { IRoute, matchRoute } from "utils/routes";
import { useAppSelector } from "hooks/redux/useAppSelector";

import useStyles from "./styles";

interface IProps {
  route: IRoute;
  onClick: (
    event: SyntheticEvent,
    route: string,
    state?: Record<string, any>
  ) => void;
  Icon: React.ComponentType<{ className?: string }>;
  children: string;
  linkState?: Record<string, any>;
  dataTestId?: string;
}

const SideBarButton = (props: PropsWithChildren<IProps>) => {
  const { route, onClick, dataTestId, linkState, Icon, children } = props;

  const classes = useStyles();
  const activeLink = useAppSelector(state => state.sidebar.activeLink);

  const handleButtonClick = (event: SyntheticEvent) => {
    onClick(event, route.path, linkState);
  };

  return (
    <Button
      className={clsx(classes.link, {
        [classes.listItemSelected]: matchRoute(activeLink, [route])
      })}
      onClick={handleButtonClick}
      data-path={route.path}
      aria-label={route.moduleName}
      data-test-id={dataTestId}
    >
      <Icon className={classes.icon} />
      <div className={classes.listItemText}>{children}</div>
    </Button>
  );
};

export default React.memo(SideBarButton);
