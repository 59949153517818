import React from "react";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";

import ErrorBoundary from "components/ErrorBoundary";
import DateRangeSelect from "components/DateRangeSelect";
import DownloadIcon from "components/Icons/Download";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { checkCurrentRaceEntriesTableTab } from "../../helper";

import useStyles from "./styles";

interface IProps {
  className?: string;
}

const PickerFilter = (props: IProps) => {
  const { className } = props;

  const classes = useStyles();

  const selectedTab = useAppSelector(
    state => state.preRaceScreeningPage.selectedTab
  );

  const { isRaceDayTab } = checkCurrentRaceEntriesTableTab(selectedTab.value);

  return (
    <div className={clsx(className, classes.pickerFilter)}>
      {!isRaceDayTab && (
        <ErrorBoundary>
          <DateRangeSelect />
        </ErrorBoundary>
      )}

      <IconButton className={classes.downloadButton} size="small">
        <DownloadIcon className={classes.downloadIcon} />
      </IconButton>
    </div>
  );
};

export default React.memo(PickerFilter);
