import React, { HTMLAttributes } from "react";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";
import { transformViewDate } from "utils/date-utils";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  date: string;
  bold?: boolean;
}

const DateCell = (props: IProps) => {
  const { date, bold, ...restProps } = props;

  return (
    <TableCellWrapper {...restProps} centered bold={bold}>
      {transformViewDate(date, null, "MM/dd/yy")}
    </TableCellWrapper>
  );
};

export default React.memo(DateCell);
