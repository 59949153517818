import { racehorse360 } from "@tsg/1st-grpc-web";

import { ISelectOption } from "interfaces/ISelectOption";
import { EModalConfirmationTypes } from "interfaces/VetExamState";
import { EMPTY_STRING } from "common/constants";
import {
  buttonGroupColors,
  ECustomFieldsValues,
  EVetExamFields,
  FRONT_ID_PREFIX,
  IExamDetail,
  IExamJog,
  IExamDetailObservation,
  IVetExamLocalState,
  createVetExamInitialState,
  createEmptyExamJog,
  createEmptyExamDetail,
  generateDummyId
} from "./options";
import { IButtonGroup } from "../ButtonsGroup/ButtonsGroup";

export const CUSTOM_VALUE = "custom_value";

export interface IModalsTypes {
  isPauseOrCloseModal: boolean;
  isResetModal: boolean;
}

export interface IUsingFields {
  isReasonField: boolean;
  isJogConditionField: boolean;
  isJogScoreField: boolean;
  isJogInfoField: boolean;
  isJogLimbField: boolean;
  isResultField: boolean;
  isRiskLevelField: boolean;
  isNoteField: boolean;
  isCommentField: boolean;
  isDetailLimbField: boolean;
  isDetailLocationField: boolean;
  isDetailStructureField: boolean;
  isDetailObservationField: boolean;
  isDetailConditionField: boolean;
  isDetailSeverityField: boolean;
  isDetailNoteField: boolean;
}

type TExamFormEntriesType = IExamJog | IExamDetail | IExamDetailObservation;

export enum EExamResult {
  PASSED = "Passed",
  FAILED = "Failed",
  NO_RESULT = "-"
}

export const checkTypeModal = (
  type: EModalConfirmationTypes
): IModalsTypes => ({
  isPauseOrCloseModal: type === EModalConfirmationTypes.PAUSE_OR_CLOSE,
  isResetModal: type === EModalConfirmationTypes.RESET
});

export const getExamResult = (
  result: racehorse360.HorseExamResult
): EExamResult => {
  switch (result) {
    case racehorse360.HorseExamResult.HORSE_EXAM_RESULT_PASSED: {
      return EExamResult.PASSED;
    }
    case racehorse360.HorseExamResult.HORSE_EXAM_RESULT_FAILED: {
      return EExamResult.FAILED;
    }
    default: {
      return EExamResult.NO_RESULT;
    }
  }
};

export const getSelectField = (
  exam: racehorse360.IHorseExam,
  field: EVetExamFields
): string | null => {
  return exam && exam[field] ? String(exam[field]) : null;
};

export const checkUsingFields = (fieldName: EVetExamFields): IUsingFields => {
  return {
    isJogConditionField: fieldName === EVetExamFields.JOG_CONDITION,
    isJogScoreField: fieldName === EVetExamFields.JOG_SCORE,
    isJogInfoField: fieldName === EVetExamFields.JOG_INFO,
    isJogLimbField: fieldName === EVetExamFields.JOG_LIMB,
    isReasonField: fieldName === EVetExamFields.REASON,
    isResultField: fieldName === EVetExamFields.RESULT,
    isRiskLevelField: fieldName === EVetExamFields.RISK_LEVEL,
    isNoteField: fieldName === EVetExamFields.INTERNAL_NOTE,
    isCommentField: fieldName === EVetExamFields.COMMENT,
    isDetailLimbField: fieldName === EVetExamFields.DETAIL_LIMB,
    isDetailLocationField: fieldName === EVetExamFields.DETAIL_LOCATION,
    isDetailStructureField: fieldName === EVetExamFields.DETAIL_STRUCTURE,
    isDetailObservationField: fieldName === EVetExamFields.DETAIL_OBSERVATION,
    isDetailConditionField: fieldName === EVetExamFields.DETAIL_CONDITION,
    isDetailSeverityField: fieldName === EVetExamFields.DETAIL_SEVERITY,
    isDetailNoteField: fieldName === EVetExamFields.DETAIL_NOTE
  };
};

interface GetFieldReturnType {
  isJogField: boolean;
  isDetailField: boolean;
  isObservationField: boolean;
}
export const getFieldType = (fieldName: EVetExamFields): GetFieldReturnType => {
  const {
    isJogScoreField,
    isJogLimbField,
    isJogInfoField,
    isJogConditionField,
    isDetailSeverityField,
    isDetailConditionField,
    isDetailObservationField,
    isDetailLocationField,
    isDetailLimbField,
    isDetailStructureField,
    isDetailNoteField
  } = checkUsingFields(fieldName);

  const isJogField = [
    isJogScoreField,
    isJogLimbField,
    isJogConditionField,
    isJogInfoField
  ].some(Boolean);

  const isDetailField = [
    isDetailLocationField,
    isDetailLimbField,
    isDetailStructureField
  ].some(Boolean);

  const isObservationField = [
    isDetailSeverityField,
    isDetailConditionField,
    isDetailObservationField,
    isDetailNoteField
  ].some(Boolean);

  return {
    isJogField,
    isDetailField,
    isObservationField
  };
};

export const createCustomOption = (name: string): ISelectOption => {
  return { name, value: CUSTOM_VALUE };
};

export const checkSelectingFieldsOneEntry = (
  entity: TExamFormEntriesType
): boolean => {
  const observationsField = "observationEntries";

  return Object.keys(entity)
    .filter(field => {
      if (observationsField in entity) {
        return field !== "id" && field !== observationsField;
      }

      return field !== "id";
    })
    .some((field: EVetExamFields) => Boolean(entity[field]));
};

export const mapButtons = (list = [], key: EVetExamFields): IButtonGroup[] => {
  const { isJogScoreField, isDetailSeverityField } = checkUsingFields(key);

  if (isDetailSeverityField) {
    return list
      .sort((a, b) => Number(a.shortDescription) - Number(b.shortDescription))
      .map((item, index) => ({
        ...item,
        value: item.shortDescription,
        description: item.description,
        ...buttonGroupColors[index]
      }));
  }

  if (isJogScoreField) {
    return list
      .sort((a, b) => Number(a.value) - Number(b.value))
      .map((item, index) => ({
        ...item,
        value: item.value,
        description: item.description,
        ...buttonGroupColors[index]
      }));
  }
};

export const mapOptions = (list = []): any[] => {
  return list
    ?.map(item => ({
      name: item.description,
      value: item.id
    }))
    .sort((i1, i2) => i1.name.localeCompare(i2.name));
};

export const mapIHorseExamJogToLocalStateJog = (
  jog: racehorse360.IHorseExamJog
): IExamJog => {
  const customJogLimb = jog.customJogLimb?.value;
  const customJogInfo = jog.customJogInfo?.value;
  const customJogCondition = jog.customJogCondition?.value;

  return {
    id: jog.id,
    [EVetExamFields.JOG_SCORE]: jog.jogScoreId,
    [EVetExamFields.JOG_LIMB]: customJogLimb ? CUSTOM_VALUE : jog.jogLimbId,
    [ECustomFieldsValues.CUSTOM_JOG_LIMB]: customJogLimb ? customJogLimb : null,
    [EVetExamFields.JOG_INFO]: customJogInfo ? CUSTOM_VALUE : jog.jogInfoId,
    [ECustomFieldsValues.CUSTOM_JOG_INFO]: customJogInfo ? customJogInfo : null,
    [EVetExamFields.JOG_CONDITION]: customJogCondition
      ? CUSTOM_VALUE
      : jog.jogConditionId,
    [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: customJogCondition
      ? customJogCondition
      : null
  };
};

export const mapHorseExamDetailObservationToLocalStateObservation = (
  observation: racehorse360.IHorseExamDetailObservationEntry
): IExamDetailObservation => {
  const customDetailCondition = observation.customDetailCondition?.value;
  const customDetailObservation = observation.customDetailObservation?.value;

  return {
    id: observation.id,
    [EVetExamFields.DETAIL_NOTE]: observation.notes || EMPTY_STRING,
    [EVetExamFields.DETAIL_SEVERITY]: observation.detailSeverityId,
    [EVetExamFields.DETAIL_OBSERVATION]: customDetailObservation
      ? CUSTOM_VALUE
      : observation.detailObservationId,
    [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: customDetailObservation
      ? customDetailObservation
      : null,
    [EVetExamFields.DETAIL_CONDITION]: customDetailCondition
      ? CUSTOM_VALUE
      : observation.detailConditionId,
    [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: customDetailCondition
      ? customDetailCondition
      : null
  };
};

export const mapIHorseExamDetailToLocalStateDetail = (
  detail: racehorse360.IHorseExamDetail
): IExamDetail => {
  const customDetailLimb = detail.customDetailLimb?.value;
  const customDetailStructure = detail.customDetailStructure?.value;
  const customDetailLocation = detail.customDetailLocation?.value;

  return {
    id: detail.id,
    [EVetExamFields.DETAIL_LIMB]: customDetailLimb
      ? CUSTOM_VALUE
      : detail.detailLimbId,
    [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: customDetailLimb
      ? customDetailLimb
      : null,
    [EVetExamFields.DETAIL_STRUCTURE]: customDetailStructure
      ? CUSTOM_VALUE
      : detail.detailStructureId,
    [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: customDetailStructure
      ? customDetailStructure
      : null,
    [EVetExamFields.DETAIL_LOCATION]: customDetailLocation
      ? CUSTOM_VALUE
      : detail.detailLocationId,
    [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: customDetailLocation
      ? customDetailLocation
      : null,
    observationEntries: detail.observationEntries?.map(
      mapHorseExamDetailObservationToLocalStateObservation
    )
  };
};

export const mapStateExamJogToRequestExamJog = (
  jog: IExamJog,
  horseExamId: string
): racehorse360.IHorseExamJog => {
  const result: racehorse360.IHorseExamJog = {
    horseExamId,
    [EVetExamFields.JOG_SCORE]: jog[EVetExamFields.JOG_SCORE],
    [EVetExamFields.JOG_LIMB]: jog[ECustomFieldsValues.CUSTOM_JOG_LIMB]
      ? null
      : jog[EVetExamFields.JOG_LIMB],
    [ECustomFieldsValues.CUSTOM_JOG_LIMB]: jog[
      ECustomFieldsValues.CUSTOM_JOG_LIMB
    ]
      ? { value: jog[ECustomFieldsValues.CUSTOM_JOG_LIMB] }
      : null,
    [EVetExamFields.JOG_INFO]: jog[ECustomFieldsValues.CUSTOM_JOG_INFO]
      ? null
      : jog[EVetExamFields.JOG_INFO],
    [ECustomFieldsValues.CUSTOM_JOG_INFO]: jog[
      ECustomFieldsValues.CUSTOM_JOG_INFO
    ]
      ? { value: jog[ECustomFieldsValues.CUSTOM_JOG_INFO] }
      : null,
    [EVetExamFields.JOG_CONDITION]: jog[
      ECustomFieldsValues.CUSTOM_JOG_CONDITION
    ]
      ? null
      : jog[EVetExamFields.JOG_CONDITION],
    [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: jog[
      ECustomFieldsValues.CUSTOM_JOG_CONDITION
    ]
      ? { value: jog[ECustomFieldsValues.CUSTOM_JOG_CONDITION] }
      : null
  };

  if (jog.id.indexOf(FRONT_ID_PREFIX) === -1) {
    result.id = jog.id;
  }

  return result;
};

export const mapStateExamDetailObservationToRequestExamDetailObservation = (
  observation: IExamDetailObservation,
  examDetailId: string
): racehorse360.IHorseExamDetailObservationEntry => {
  const result: racehorse360.IHorseExamDetailObservationEntry = {
    horseExamDetailId: examDetailId,
    [EVetExamFields.DETAIL_OBSERVATION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION
    ]
      ? null
      : observation[EVetExamFields.DETAIL_OBSERVATION],
    [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION
    ]
      ? { value: observation[ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION] }
      : null,
    [EVetExamFields.DETAIL_CONDITION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_CONDITION
    ]
      ? null
      : observation[EVetExamFields.DETAIL_CONDITION],
    [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_CONDITION
    ]
      ? { value: observation[ECustomFieldsValues.CUSTOM_DETAIL_CONDITION] }
      : null,
    [EVetExamFields.DETAIL_SEVERITY]:
      observation[EVetExamFields.DETAIL_SEVERITY],
    [EVetExamFields.DETAIL_NOTE]: observation[EVetExamFields.DETAIL_NOTE]
  };

  if (observation.id.indexOf(FRONT_ID_PREFIX) === -1) {
    result.id = observation.id;
  }

  return result;
};

export const mapStateExamDetailToRequestExamDetail = (
  detail: IExamDetail,
  horseExamId: string
): racehorse360.IHorseExamDetail => {
  let detailId = null;

  if (detail.id.indexOf(FRONT_ID_PREFIX) === -1) {
    detailId = detail.id;
  }

  return {
    id: detailId,
    horseExamId,
    [EVetExamFields.DETAIL_LIMB]: detail[ECustomFieldsValues.CUSTOM_DETAIL_LIMB]
      ? null
      : detail[EVetExamFields.DETAIL_LIMB],
    [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_LIMB
    ]
      ? { value: detail[ECustomFieldsValues.CUSTOM_DETAIL_LIMB] }
      : null,
    [EVetExamFields.DETAIL_STRUCTURE]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE
    ]
      ? null
      : detail[EVetExamFields.DETAIL_STRUCTURE],
    [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE
    ]
      ? { value: detail[ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE] }
      : null,
    [EVetExamFields.DETAIL_LOCATION]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_LOCATION
    ]
      ? null
      : detail[EVetExamFields.DETAIL_LOCATION],
    [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_LOCATION
    ]
      ? { value: detail[ECustomFieldsValues.CUSTOM_DETAIL_LOCATION] }
      : null,
    observationEntries: detail.observationEntries.map(item =>
      mapStateExamDetailObservationToRequestExamDetailObservation(
        item,
        detailId
      )
    )
  };
};

export const getVetExamRequestState = (
  currentExam: Partial<racehorse360.HorseExam>,
  state: IVetExamLocalState
): Partial<racehorse360.HorseExam> => {
  return {
    ...currentExam,
    facility: null,
    horse: null,
    assignedUser: null,
    workoutRequest: null,
    upcomingRaceEntry: null,
    result: Number(state[EVetExamFields.RESULT]),
    reason: Number(state[EVetExamFields.REASON]),
    riskLevel: Number(state[EVetExamFields.RISK_LEVEL]),
    note: state[EVetExamFields.INTERNAL_NOTE],
    commentToTrainer: { body: state[EVetExamFields.COMMENT] },
    jogs: state.examJogs.map(jog =>
      mapStateExamJogToRequestExamJog(jog, currentExam.id)
    ),
    examDetails: state.examDetails.map(detail =>
      mapStateExamDetailToRequestExamDetail(detail, currentExam.id)
    )
  };
};

export const getLocalStateExam = (
  exam: racehorse360.IHorseExam
): IVetExamLocalState => ({
  id: exam.id,
  [EVetExamFields.REASON]:
    getSelectField(exam, EVetExamFields.REASON) || EMPTY_STRING,
  [EVetExamFields.RESULT]:
    getSelectField(exam, EVetExamFields.RESULT) || EMPTY_STRING,
  [EVetExamFields.RISK_LEVEL]: getSelectField(exam, EVetExamFields.RISK_LEVEL),
  [EVetExamFields.INTERNAL_NOTE]: exam.note || EMPTY_STRING,
  [EVetExamFields.COMMENT]: exam.commentToTrainer?.body || EMPTY_STRING,
  [EVetExamFields.EXAM_JOGS]: exam.jogs.map(mapIHorseExamJogToLocalStateJog),
  [EVetExamFields.EXAM_DETAILS]: exam.examDetails.map(
    mapIHorseExamDetailToLocalStateDetail
  )
});

export const getResetLocalStateExam = (
  exam: racehorse360.IHorseExam
): IVetExamLocalState => ({
  ...createVetExamInitialState(exam),
  [EVetExamFields.REASON]:
    exam.workoutRequest || exam.upcomingRaceEntry
      ? String(exam.reason)
      : EMPTY_STRING
});

export const getCombinedLocalStateExam = (
  destExam: racehorse360.IHorseExam,
  srcExam: racehorse360.IHorseExam
): IVetExamLocalState => {
  const srcJogs = srcExam.jogs.length
    ? srcExam.jogs.map(mapIHorseExamJogToLocalStateJog)
    : [createEmptyExamJog()];

  const srcExamDetails = srcExam.examDetails.length
    ? srcExam.examDetails.map(mapIHorseExamDetailToLocalStateDetail)
    : [createEmptyExamDetail()];

  return {
    id: destExam.id,
    [EVetExamFields.REASON]:
      getSelectField(destExam, EVetExamFields.REASON) || EMPTY_STRING,
    [EVetExamFields.RESULT]:
      getSelectField(srcExam, EVetExamFields.RESULT) || EMPTY_STRING,
    [EVetExamFields.RISK_LEVEL]: getSelectField(
      srcExam,
      EVetExamFields.RISK_LEVEL
    ),
    [EVetExamFields.INTERNAL_NOTE]: srcExam.note || EMPTY_STRING,
    [EVetExamFields.COMMENT]: srcExam.commentToTrainer?.body || EMPTY_STRING,
    [EVetExamFields.EXAM_JOGS]: srcJogs.map((jog, i) => ({
      ...jog,
      id: destExam.jogs?.[i]?.id || generateDummyId()
    })),
    [EVetExamFields.EXAM_DETAILS]: srcExamDetails.map((detail, i) => ({
      ...detail,
      id: destExam.examDetails?.[i]?.id || generateDummyId(),
      observationEntries: detail.observationEntries.map((oe, j) => ({
        ...oe,
        id:
          destExam.examDetails?.[i]?.observationEntries?.[j]?.id ||
          generateDummyId()
      }))
    }))
  };
};

export const checkSelectingExamFormFields = (
  examState: IVetExamLocalState
): boolean => {
  if (!examState) return false;

  const hasSelectedJogsFields = examState.examJogs.some(
    checkSelectingFieldsOneEntry
  );
  const hasSelectedDetailsFields = examState.examDetails.some(
    checkSelectingFieldsOneEntry
  );
  const hasSelectedObservationsFields = examState.examDetails.some(detail => {
    return detail.observationEntries.some(checkSelectingFieldsOneEntry);
  });
  const hasSelectedExamFields = Object.keys(examState)
    .filter(
      field =>
        field !== "id" &&
        field !== EVetExamFields.EXAM_JOGS &&
        field !== EVetExamFields.EXAM_DETAILS
    )
    .some(field => Boolean(examState[field]));

  return [
    hasSelectedJogsFields,
    hasSelectedDetailsFields,
    hasSelectedObservationsFields,
    hasSelectedExamFields
  ].some(Boolean);
};
