import { racehorse360 } from "@tsg/1st-grpc-web";
import { EPreRacesTableTab } from "pages/PreRaceScreeningPage/types";
import { checkCurrentRaceEntriesTableTab } from "pages/PreRaceScreeningPage/helper";
import { IExamsTableRowData, TFlagPriority } from "./types";

export enum EFlagPriority {
  HIGHEST = "highest",
  MEDIUM = "medium",
  LOWEST = "lowest",
  NONE = "none"
}

export const flagLevels = {
  [EFlagPriority.NONE]: "#7f7f7f",
  [EFlagPriority.LOWEST]: "#e3be00",
  [EFlagPriority.MEDIUM]: "#d82f44",
  [EFlagPriority.HIGHEST]: "#9100ff"
};

export const getFlagPriority = (priority: TFlagPriority): EFlagPriority => {
  switch (priority) {
    case racehorse360.WorkoutRequestFlagPriority
      .WORKOUT_REQUEST_FLAG_PRIORITY_LOWER: {
      return EFlagPriority.LOWEST;
    }
    case racehorse360.UpcomingRaceEntryFlagPriority
      .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_LOWER: {
      return EFlagPriority.LOWEST;
    }
    case racehorse360.WorkoutRequestFlagPriority
      .WORKOUT_REQUEST_FLAG_PRIORITY_MEDIUM: {
      return EFlagPriority.MEDIUM;
    }
    case racehorse360.UpcomingRaceEntryFlagPriority
      .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_MEDIUM: {
      return EFlagPriority.MEDIUM;
    }
    case racehorse360.WorkoutRequestFlagPriority
      .WORKOUT_REQUEST_FLAG_PRIORITY_HIGHEST: {
      return EFlagPriority.HIGHEST;
    }
    case racehorse360.UpcomingRaceEntryFlagPriority
      .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_HIGHEST: {
      return EFlagPriority.HIGHEST;
    }
    default: {
      return EFlagPriority.NONE;
    }
  }
};

export const examsRowDataMapper = (
  entity: racehorse360.IWorkoutRequest | racehorse360.IUpcomingRaceEntry,
  selectedTabValue?: EPreRacesTableTab
): IExamsTableRowData => {
  if (entity instanceof racehorse360.WorkoutRequest) {
    return {
      eventId: entity.id,
      eventDate: entity.date,
      eventCreationDate: entity.createdOn,
      eventStatus: entity.status,
      eventFlag: entity.flag,
      eventFacility: entity.facility,
      eventRejectionNote: entity.rejectionNote,
      hasEventComment: entity.hasComment,
      pastEventsCount: entity.pastWorkoutRequestsCount,
      examId: entity.workoutExam?.id,
      examStatus: entity.workoutExam?.status,
      examCreatedById: entity.workoutExam?.createdById,
      examType: entity.workoutExam?.type,
      examAssignedUser: entity.workoutExam?.assignedUser,
      examNote: entity.examReasonNote,
      horse: entity.horse,
      highSpeedFurlongs: entity.highSpeedFurlongs
    };
  } else if (entity instanceof racehorse360.UpcomingRaceEntry) {
    const { isEntryExamsTab, isRaceDayTab } =
      checkCurrentRaceEntriesTableTab(selectedTabValue);
    let currentExamId: string;
    let currentExamStatus: racehorse360.HorseExamStatus;

    if (isEntryExamsTab) {
      currentExamId = entity.entryExam?.id;
      currentExamStatus = entity.entryExam?.status;
    } else if (isRaceDayTab) {
      currentExamId = entity.raceDayExam?.id;
      currentExamStatus = entity.raceDayExam?.status;
    }

    return {
      eventId: entity.id,
      eventDate: entity.raceDate,
      eventCreationDate: entity.createdOn,
      eventStatus: entity.status,
      eventFlagPriority: entity.flagPriority,
      eventFacility: entity.facility,
      examId: currentExamId,
      examStatus: currentExamStatus,
      examNote: entity.entryExamNote,
      examType: entity.entryExam?.type,
      examResult: entity.entryExam?.result,
      horse: entity.horse,
      highSpeedFurlongs: entity.highSpeedFurlongs
    };
  } else {
    throw new Error(
      `Incorrect entity passed as parameter. It should be ${racehorse360.UpcomingRaceEntry.name}
      or ${racehorse360.WorkoutRequest.name}`
    );
  }
};
