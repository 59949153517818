import ITab from "interfaces/Tab";
import { racehorse360 } from "@tsg/1st-grpc-web";

export enum EPreRacesTableTab {
  ENTRIES = "Entries",
  ENTRY_EXAMS = "Entry Exams",
  RESULTS = "Results",
  RACE_DAY = "Race Day"
}

export enum EPreRaceTableColumn {
  TRAINER = "trainer",
  RACE_DATE = "raceDate",
  FLAG_REQUEST = "flagRequest",
  RISK_LEVEL = "riskLevel",
  BARN = "barn",
  CSA = "csa",
  HSF = "hsf",
  DLW = "dlw",
  DLR = "dlr",
  ACTIVE_VET_LISTS = "activeVetList",
  RISK_FACTORS = "riskFactors",
  EXAM = "exam",
  ENTRY_REVIEW = "entryReview",
  EXTENDER = "extender"
}

export enum EPreRaceTableColumnSorting {
  HORSE_NAME = "horse.name",
  TRAINER_LAST_NAME = "horse.trainer.lastName",
  RACE_DATE = "raceDate",
  IS_FLAGGED = "isFlagged",
  FLAG_PRIORITY = "flagPriority",
  RISK_LEVEL = "horse.healthWatchStatus",
  HIGH_SPEED_FURLONGS = "highSpeedFurlongs",
  BARN_NAME = "horse.barn.name",
  LAST_WORKOUT_DATE = "horse.lastWorkoutDate",
  LAST_RACE_DATE = "horse.lastRaceDate",
  ACTIVE_VET_LISTS_COUNT = "horse.activeVetListsCount",
  RISK_FACTORS_COUNT = "horse.riskFactorsCount"
}

export interface IPreRaceTableTab extends ITab<EPreRacesTableTab> {
  entryStatuses: racehorse360.UpcomingRaceEntryStatus[];
  itemsPerPage: number;
  additionalRequestedFields?: string[];
}
