import { racehorse360 } from "@tsg/1st-grpc-web";

import { getPersonFullName } from "utils/person";

const ABC_LIST = "ABC List";

export const getExamAssigneeName = (exam: racehorse360.IHorseExam): string => {
  if (!exam.assignedUser) return ABC_LIST;

  return getPersonFullName(exam.assignedUser, "%f %m %L");
};
