import { SortOrder, SortOrderExtended } from "interfaces/SortOrder";

interface CheckSortingReturnType {
  isAsc: boolean;
  isAscNullsFirst: boolean;
  isAscNullsLast: boolean;
  isDesc: boolean;
  isDescNullsFirst: boolean;
  isDescNullsLast: boolean;
  isOneOfAsc: boolean;
  isOneOfDesc: boolean;
}

export const checkSortingType = (
  sort: SortOrderExtended | SortOrder
): CheckSortingReturnType => ({
  isAsc: sort === SortOrder.ASC,
  isAscNullsFirst: sort === SortOrderExtended.ASC_NULLS_FIRST,
  isAscNullsLast: sort === SortOrderExtended.ASC_NULLS_LAST,
  isDesc: sort === SortOrder.DESC,
  isDescNullsFirst: sort === SortOrderExtended.DESC_NULLS_FIRST,
  isDescNullsLast: sort === SortOrderExtended.DESC_NULLS_LAST,
  isOneOfAsc: [
    sort === SortOrderExtended.ASC_NULLS_LAST,
    sort === SortOrderExtended.ASC_NULLS_FIRST,
    sort === SortOrderExtended.ASC_SIMILARITY,
    sort === SortOrder.ASC
  ].some(Boolean),
  isOneOfDesc: [
    sort === SortOrderExtended.DESC_NULLS_LAST,
    sort === SortOrderExtended.DESC_NULLS_FIRST,
    sort === SortOrderExtended.DESC_SIMILARITY,
    sort === SortOrder.DESC
  ].some(Boolean)
});

export const reverseSortOrderValue = (value: SortOrder): SortOrder => {
  const { isAsc } = checkSortingType(value);

  return isAsc ? SortOrder.DESC : SortOrder.ASC;
};
