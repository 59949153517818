import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => {
  const {
    widthDesktopDrawer,
    widthTabletDrawer,
    palette: { primary }
  } = theme;

  return {
    overlayPage: {
      width: "100%"
    },
    root: {
      position: "relative",
      display: "flex",
      overflowX: "hidden",
      maxWidth: `calc(100% - ${widthDesktopDrawer}px)`,
      [theme.breakpoints.down(Breakpoints.MD_960)]: {
        maxWidth: `calc(100% - ${widthTabletDrawer}px)`
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        maxWidth: "100%",
        position: "inherit"
      }
    },
    vetExamForm: {
      width: "70%",
      overflowY: "hidden",
      transition: "width .5s",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(Breakpoints.MD_960)]: {
        width: `calc(100% - 348px)`
      },
      [theme.breakpoints.down(Breakpoints.SX_720)]: {
        width: `calc(100% - 300px)`
      },
      "&.showRightPanel": {
        width: "100%"
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        borderRight: "none",
        transition: "none",
        width: "100%"
      }
    },
    mobileHeader: {
      display: "none",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "block",
        padding: "0 2px",
        borderBottom: `1px solid ${primary.light}`,
        "& .MuiTypography-root": {
          height: 60,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }
      }
    },
    closeButton: {
      fontSize: 16,
      justifyContent: "flex-start",
      color: primary.dark,
      paddingLeft: 12,
      textTransform: "capitalize"
    },
    mobileTitle: {
      fontSize: 18
    },
    hideMenuButton: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      top: 8,
      right: 12,
      cursor: "pointer",
      "&.MuiIconButton-root:hover": {
        backgroundColor: "transparent"
      },
      "&.revert": {
        transform: "rotateY(180deg)"
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "none"
      }
    },
    scrollableContainer: {
      overflowY: "auto",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        paddingTop: 54
      }
    },
    loader: {
      position: "absolute",
      left: 0,
      top: 0,
      backgroundColor: "#fff",
      opacity: 0.5,
      zIndex: 2
    },
    pageLoader: {
      width: "calc(100% - 220px)",
      marginRight: 220,
      backgroundColor: "#f6fbfa",
      [theme.breakpoints.down(Breakpoints.MD_960)]: {
        width: "calc(100% - 84px)",
        marginRight: 84
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        width: "100%"
      }
    },
    vetExamsFormLoader: {
      height: "calc(100vh - 118px)",
      marginTop: "calc(118px - 100vh)",
      position: "sticky",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "none"
      }
    },
    reasonsSelect: {
      position: "relative",
      marginBottom: 18,
      width: 263,
      [theme.breakpoints.down(Breakpoints.MD_960)]: {
        width: 244
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        width: "100%"
      }
    },
    actionButtons: {
      display: "flex",
      padding: "40px 24px",
      justifyContent: "flex-end",
      backgroundColor: "#f6f6f6",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        padding: "12px 0 34px",
        justifyContent: "center"
      }
    },
    button: {
      height: 45,
      color: theme.palette.common.white,
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      letterSpacing: 0.5,
      borderRadius: 2,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none"
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    pauseAndClose: {
      backgroundColor: "#333",
      width: 163
    },
    submit: {
      backgroundColor: "#02c092",
      width: 176,
      marginLeft: 20,
      "&:hover": {
        backgroundColor: "#7ad8b3"
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        width: 164,
        marginLeft: 16
      }
    },
    fieldsWrapper: {
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      gap: 20,
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        gap: 16
      }
    },
    entryHeader: {
      position: "relative",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    removeButton: {
      position: "absolute",
      right: 0,
      padding: 9,
      marginRight: -12,
      color: theme.palette.text.secondary
    },
    useLastExamEntryCheckboxContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      padding: 4,
      marginBottom: -42,
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        marginBottom: -24
      }
    },
    useLastExamEntryCheckboxLabel: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      color: "#1a1a1a",
      fontSize: 14
    },
    noPastExams: {
      opacity: 0.5,
      cursor: "default"
    },
    italic: {
      fontStyle: "italic"
    }
  };
});
