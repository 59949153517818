import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import VetExamOverlay from "components/VetExamOverlay";
import DataAbsenceView from "components/VetExamsTable/DataAbsenceView";
import { IExamsTableRowData } from "components/VetExamsTable/types";
import VetExamsTable from "components/VetExamsTable";
import { useRiskFactorsColumnWidth } from "components/VetExamsTable/useRiskFactorsColumnWidth";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { useApiClient, useRacehorse360Api } from "hooks/api";
import { setIsExamActive } from "store/actions/vetExam";
import { resetCheckedIds } from "store/actions/vetWorkoutsPage";
import { EColumnName } from "./options";
import WorkoutsTableHeader from "./WorkoutsTableHeader";
import WorkoutRow from "./WorkoutRow";
import { createConfigTable } from "./WorkoutRow/helper";
import { checkCurrentWorkoutsTableTab, WorkoutsTableTab } from "../helper";

import useStyles from "./styles";

interface IProps {
  rows: IExamsTableRowData[];
  isFetchingNextPage: boolean;
  tableBodyRef: React.RefObject<HTMLDivElement>;
  searchValue: string;
  onClearSearch: (value: boolean) => void;
  onUpdateTableRows: (rows: IExamsTableRowData[]) => void;
}

const WorkoutsTable = (props: IProps) => {
  const {
    rows,
    isFetchingNextPage,
    tableBodyRef,
    searchValue,
    onClearSearch,
    onUpdateTableRows
  } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();
  const { horseExamClient } = useApiClient();
  const { showErrorSnack } = useSnackbar();
  const { useCreateHorseExam } = useRacehorse360Api();

  const [activeExamId, setActiveExamId] = useState<string>();

  const { selectedTab, orderBy, isExamActive } = useAppSelector(state => {
    const { selectedTab, orderBy } = state.vetWorkoutsPage;
    const isExamActive = state.vetExam.isExamActive;

    return {
      selectedTab,
      orderBy,
      isExamActive
    };
  });

  const { isRequestsTab, isExamsTab, isPassedTab, isFailedTab, isRejectedTab } =
    checkCurrentWorkoutsTableTab(selectedTab.value);

  const columns = useMemo(() => createConfigTable(selectedTab), [selectedTab]);

  const riskFactorsColumnWidth = useRiskFactorsColumnWidth(
    rows,
    isRequestsTab || isExamsTab
  );
  const classes = useStyles({ riskFactorsColumnWidth });

  const extraColumnsClassName = clsx({
    [classes.rowContentRequests]: isRequestsTab,
    [classes.rowContentExams]: isExamsTab,
    [classes.rowContentPassed]: isPassedTab,
    [classes.rowContentFailed]: isFailedTab,
    [classes.rowContentRejected]: isRejectedTab
  });

  const shouldRenderTrainerAccordions =
    isExamsTab && orderBy === EColumnName.TRAINER_LASTNAME;

  const handleClearSearch = useCallback(() => {
    onClearSearch(true);
  }, [onClearSearch]);

  const refetchData = useCallback(async (): Promise<void> => {
    await queryClient.invalidateQueries({
      queryKey: [
        `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`
      ]
    });
  }, [currentUser.rh360Id, searchValue]);

  const { mutateAsync: createHorseExam } = useCreateHorseExam();

  const handleExamClick = useCallback(
    (rowData: IExamsTableRowData) => {
      const { examId, eventFacility: workoutRequestFacility, horse } = rowData;

      if (examId) {
        horseExamClient
          .listHorseExams({
            query: {
              ids: [examId]
            },
            getOptions: {
              select: ["status"]
            }
          })
          .then(result => {
            const isExamAvailable = [
              racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED,
              racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_IN_PROGRESS,
              racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_PENDING
            ].includes(result.horseExams[0].status);

            if (isExamAvailable) {
              setActiveExamId(examId);

              dispatch(resetCheckedIds());
              dispatch(setIsExamActive(true));
            } else {
              showErrorSnack("This exam has already been completed");
              return refetchData();
            }
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        const workoutExamPayload: racehorse360.ICreateHorseExamRequest = {
          facilityId: workoutRequestFacility?.id,
          horseId: horse?.id
        };

        createHorseExam(workoutExamPayload).then(actualExam => {
          setActiveExamId(actualExam.id);
          dispatch(setIsExamActive(true));
        });
      }
    },
    [dispatch]
  );

  const handleExamClose = () => {
    dispatch(setIsExamActive(false));
    setActiveExamId(null);
  };

  const renderRow = (rowData: IExamsTableRowData, index: number) => {
    return (
      <WorkoutRow
        index={index}
        rowData={rowData}
        columns={columns}
        refetchData={refetchData}
        columnsClassName={extraColumnsClassName}
        onExamClick={handleExamClick}
        onUpdateTableRows={onUpdateTableRows}
      />
    );
  };

  const noWorkoutsContent = useMemo(() => {
    let tabName: string;

    switch (selectedTab.value) {
      case WorkoutsTableTab.REQUESTS: {
        tabName = "Workout Requests";
        break;
      }
      case WorkoutsTableTab.EXAMS: {
        tabName = "Exams";
        break;
      }
      default: {
        tabName = `${selectedTab.name} Exams`;
      }
    }

    return (
      <DataAbsenceView
        isSearchResults={Boolean(searchValue?.length)}
        onClearSearch={handleClearSearch}
        searchValue={searchValue}
        message={`No ${tabName} to Display for Date Range`}
      />
    );
  }, [selectedTab.value, searchValue]);

  const tableHeaderContent = useMemo(
    () => (
      <WorkoutsTableHeader
        columnsClassName={extraColumnsClassName}
        columns={columns}
      />
    ),
    [columns, extraColumnsClassName]
  );

  if (isExamActive && activeExamId) {
    return (
      <VetExamOverlay
        examId={activeExamId}
        refetchData={refetchData}
        onClose={handleExamClose}
      />
    );
  }

  return (
    <VetExamsTable
      rows={rows}
      isMappedByTrainer={shouldRenderTrainerAccordions}
      isFetchingNextPage={isFetchingNextPage}
      tableBodyRef={tableBodyRef}
      noResultsContent={noWorkoutsContent}
      tableHeaderContent={tableHeaderContent}
      refetchData={refetchData}
      renderRow={renderRow}
      hasTrainerAccordionViewFixedCells={isExamsTab}
      shouldUseLockableRow={isExamsTab}
    />
  );
};

export default React.memo(WorkoutsTable);
