import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
  setOrderBy,
  setSelectedTabPreRaceTable,
  setTableRows
} from "store/actions/preRaceScreeningPage";
import { useAppSelector } from "hooks/redux/useAppSelector";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import { preRacesTableTabs } from "../../options";
import { EPreRaceTableColumnSorting } from "../../types";
import { checkCurrentRaceEntriesTableTab } from "../../helper";

import useStyles from "./styles";

const PreRaceTableTabs: React.FC<WithClickBlockerProps> = props => {
  const { handleBlockableClick } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedTab = useAppSelector(
    state => state.preRaceScreeningPage.selectedTab
  );

  const handleChange = (e, tabValue) => {
    if (tabValue === selectedTab.value) return;

    handleBlockableClick(() => {
      const tab = preRacesTableTabs.find(tab => tab.value === tabValue);
      const { isEntryExamsTab, isRaceDayTab } =
        checkCurrentRaceEntriesTableTab(tabValue);

      if (tab) {
        dispatch(setTableRows(null));
        dispatch(setSelectedTabPreRaceTable(tab));
        if (isEntryExamsTab || isRaceDayTab) {
          dispatch(setOrderBy(EPreRaceTableColumnSorting.TRAINER_LAST_NAME));
        }
        history.push(tab.path);
      }
    });
  };

  useEffect(() => {
    const tab = preRacesTableTabs.find(
      item => item.path === history.location.pathname
    );

    tab && dispatch(setSelectedTabPreRaceTable(tab));
  }, []);

  const tabsContent = preRacesTableTabs.map(tab => (
    <Tab
      key={tab.value}
      className={classes.tab}
      label={tab.name}
      value={tab.value}
    />
  ));

  return (
    <Tabs
      className={classes.tabs}
      value={selectedTab.value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabsContent}
    </Tabs>
  );
};

export default withClickBlocker(React.memo(PreRaceTableTabs));
