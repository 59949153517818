import React from "react";
import clsx from "clsx";

import FlagIcon from "@material-ui/icons/Flag";

import { getFlagPriority } from "components/VetExamsTable/helper";
import { TFlagPriority } from "components/VetExamsTable/types";

import useStyles from "./styles";

interface IProps {
  priority: TFlagPriority;
  className?: string;
}

const FlagPriorityIndicator = (props: IProps) => {
  const { priority, className } = props;
  const classes = useStyles();
  const flagPriorityClassName = getFlagPriority(priority);

  return (
    <FlagIcon
      className={clsx(
        classes.activeFlaggedIcon,
        classes[flagPriorityClassName],
        className
      )}
      data-test-id={"flaggedIcon"}
    />
  );
};

export default FlagPriorityIndicator;
