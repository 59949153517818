import React, { ChangeEvent, useRef, useState } from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import FlagIcon from "@material-ui/icons/Flag";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";

import {
  EFlagPriority,
  getFlagPriority
} from "components/VetExamsTable/helper";
import { TFlagPriority } from "components/VetExamsTable/types";
import Loader from "components/Loader";
import FlagPriorityPopover from "./FlagPriorityPopover";

import useStyles from "./styles";

interface IProps {
  priority: TFlagPriority;
  onChangePriority: (
    event: ChangeEvent<HTMLInputElement>,
    hasFlag: boolean
  ) => Promise<void>;
}

const MOBILE_LOADER_SIZE = 16;
const DESKTOP_LOADER_SIZE = 20;

const FlagPriority = (props: IProps) => {
  const { priority, onChangePriority } = props;

  const isPrioritySelected = Boolean(priority);
  const buttonTitle = isPrioritySelected ? "Flagged" : "Flag";
  const selectedPriority = getFlagPriority(priority);

  const classes = useStyles();
  const flagRequestRef = useRef<HTMLButtonElement>(null);
  const matchesDownXS420 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleOpenPopover = () => {
    setIsOpenPopover(true);
  };

  const handleClosePopover = () => {
    setIsOpenPopover(false);
  };

  const handleChangePriority = async (event: ChangeEvent<HTMLInputElement>) => {
    handleClosePopover();
    setShowLoader(true);

    await onChangePriority(event, isPrioritySelected);

    setShowLoader(false);
  };

  if (showLoader) {
    return (
      <Loader
        className={classes.loader}
        size={matchesDownXS420 ? MOBILE_LOADER_SIZE : DESKTOP_LOADER_SIZE}
      />
    );
  }

  return (
    <>
      <Button
        ref={flagRequestRef}
        className={clsx(classes.flaggedButton, classes[selectedPriority], {
          active: selectedPriority !== EFlagPriority.NONE
        })}
        startIcon={isPrioritySelected ? <CheckIcon /> : <FlagIcon />}
        onClick={handleOpenPopover}
        data-test-id={`${buttonTitle.toLowerCase()}Button`}
      >
        {buttonTitle}
      </Button>

      {isOpenPopover && (
        <FlagPriorityPopover
          onClose={handleClosePopover}
          anchorEl={flagRequestRef.current}
          selectedValue={selectedPriority}
          onChange={handleChangePriority}
        />
      )}
    </>
  );
};

export default React.memo(FlagPriority);
