import React from "react";
import format from "date-fns/format";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import Textarea from "components/Textarea";
import { getPersonFullName } from "utils/person";
import { TExamNote } from "../../types";

import useStyles from "../styles";

interface IProps {
  open: boolean;
  isLoading: boolean;
  examNote: TExamNote;
  horse: racehorse360.IHorse;
  note: string;
  noteLabel: string;
  onClose: () => void;
  onSave: () => void;
  onDelete: (event: React.MouseEvent) => void;
  onNoteChange: (event: React.ChangeEvent<{ value: string }>) => void;
}

const ContentMobile = (props: IProps) => {
  const {
    open,
    isLoading,
    examNote,
    horse,
    note,
    onClose,
    onSave,
    onDelete,
    onNoteChange,
    noteLabel
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper
      }}
      open={open}
    >
      {isLoading && <Loader overlay />}
      <DialogTitle className={classes.dialogTitle}>
        {noteLabel}
        <IconButton
          color="primary"
          onClick={onClose}
          size={"small"}
          classes={{
            root: classes.dialogCloseButton
          }}
        >
          <CloseIcon className={classes.dialogCloseButtonIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <span className={classes.horseName}>{horse?.name.toUpperCase()}</span>
        <span className={classes.trainerName}>
          {getPersonFullName(horse?.trainer)}
        </span>
        <Textarea
          className={classes.textarea}
          maxLength={90}
          label={format(new Date(), "MM/dd-yyyy - hh:mm aa")}
          placeholder={"Add Note"}
          rows={7}
          value={note}
          defaultValue={examNote?.body}
          onChange={onNoteChange}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {Boolean(examNote?.id) && (
          <Button className={classes.buttonDelete} onClick={onDelete}>
            DELETE
          </Button>
        )}
        <div>
          <Button className={classes.buttonCancel} onClick={onClose}>
            CANCEL
          </Button>
          <Button
            className={classes.buttonSave}
            disabled={!note.trim().length}
            onClick={onSave}
          >
            SAVE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ContentMobile;
