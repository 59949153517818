import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import history from "common/history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage/session";
import LogRocket from "logrocket";

import facilitiesReducer from "./reducers/facilities";
import vetHorseListPageReducer from "./reducers/vetHorseListPage";
import preferredConditionsPageReducer from "./reducers/preferredConditionsPage";
import trainerHorseListPageReducer from "./reducers/trainerHorseListPage";
import trainerDashboardPageForecastReducer from "./reducers/trainerDashboardPageForecast";
import workoutsPageReducer from "./reducers/workoutsPage";
import vetWorkoutsPageReducer from "./reducers/vetWorkoutsPage";
import sidebarActiveLinkReducer from "./reducers/sidebar";
import snackbarsReducer from "./reducers/snackbars";
import workOrdersPageReducer from "./reducers/workOrderPage";
import maintenanceWorkOrdersPageReducer from "./reducers/maintenanceWorkOrderPage";
import trainerHorseDetailsPageReducer from "./reducers/trainerHorseDetailsPage";
import vetExamReducer from "./reducers/vetExam";
import vetHorseDetailsPageReducer from "./reducers/vetHorseDetailsPage";
import queriesConfig from "./reducers/queriesConfig";
import racingOfficialPCPageReducer from "./reducers/racingOfficialPCPage";
import preferredConditionsMatchingHorsesSliderReducer from "./reducers/preferredConditionsMatchingHorsesSlider";
import conditionBookPageReducer from "./reducers/conditionBookPage";
import racingSecretaryStallApplicationReducer from "./reducers/racingSecretaryStallApplication";
import trackOpticsPageReducer from "./reducers/trackOpticsPage";
import preRaceScreeningReducer from "./reducers/preRaceScreeningPage";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
  whitelist: ["preferredConditionsPageReducer"]
};

const reducers = combineReducers({
  router: connectRouter(history as any),
  facilities: facilitiesReducer,
  vetHorseListPage: vetHorseListPageReducer,
  preferredConditionsPage: preferredConditionsPageReducer,
  trainerHorseListPage: trainerHorseListPageReducer,
  trainerDashboardPageForecast: trainerDashboardPageForecastReducer,
  workoutsPage: workoutsPageReducer,
  vetWorkoutsPage: vetWorkoutsPageReducer,
  sidebar: sidebarActiveLinkReducer,
  snackbars: snackbarsReducer,
  workOrdersPage: workOrdersPageReducer,
  trainerHorseDetailsPage: trainerHorseDetailsPageReducer,
  vetHorseDetailsPage: vetHorseDetailsPageReducer,
  queriesConfig: queriesConfig,
  maintenanceWorkOrdersPage: maintenanceWorkOrdersPageReducer,
  racingOfficialPCPage: racingOfficialPCPageReducer,
  preferredConditionsMatchingHorsesSlider:
    preferredConditionsMatchingHorsesSliderReducer,
  conditionBookPage: conditionBookPageReducer,
  vetExam: vetExamReducer,
  racingSecretaryStallApplication: racingSecretaryStallApplicationReducer,
  trackOpticsPage: trackOpticsPageReducer,
  preRaceScreeningPage: preRaceScreeningReducer
});

const store = createStore(
  persistReducer(persistConfig, reducers),
  compose(
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), LogRocket.reduxMiddleware())
    )
  )
);

export type AppStoreType = ReturnType<typeof reducers>;
export type AppDispatchType = typeof store.dispatch;

export default store;
