import React, { useLayoutEffect, useReducer } from "react";

type ScrollSyncContextProps = {
  panels: React.MutableRefObject<any>[];
  addPanel: (panel: React.MutableRefObject<any>) => void;
  removePanel: (panel: React.MutableRefObject<any>) => void;
  forceRecalculateScroll: () => void;
};

const initialState = {
  panels: []
};

const ScrollSyncContext =
  React.createContext<Partial<ScrollSyncContextProps>>(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_PANEL":
      return {
        ...state,
        panels: [...state.panels, action.panel]
      };

    case "REMOVE_PANEL":
      return {
        ...state,
        panels: [...state.panels.filter(p => p !== action.panel)]
      };

    default:
      return state;
  }
};

const scrollPanel = (panel, scrolledPanelData) => {
  const scrollLeftOffset =
    scrolledPanelData.scrollWidth - scrolledPanelData.clientWidth;
  const scrollTopOffset =
    scrolledPanelData.scrollHeight - scrolledPanelData.clientHeight;

  if (scrollLeftOffset) {
    panel.scrollLeft = scrolledPanelData.scrollLeft;
  }

  if (scrollTopOffset) {
    panel.scrollTop = scrolledPanelData.scrollTop;
  }
};

const scroll = {
  animationFrameId: null,
  panels: [],
  lastScrollData: null,
  enabled: true,
  scrollingPanel: false,
  callback: event => {
    const scrolledPanel = event.target;

    if (scroll.scrollingPanel === scrolledPanel) {
      return;
    }

    if (scrolledPanel) {
      scroll.lastScrollData = {
        scrollTop: scrolledPanel.scrollTop,
        scrollHeight: scrolledPanel.scrollHeight,
        scrollLeft: scrolledPanel.scrollLeft,
        scrollWidth: scrolledPanel.scrollWidth,
        clientWidth: scrolledPanel.clientWidth,
        clientHeight: scrolledPanel.clientHeight
      };
    }

    cancelAnimationFrame(scroll.animationFrameId);

    scroll.animationFrameId = requestAnimationFrame(() => {
      scroll.panels.forEach(panel => {
        if (panel !== scrolledPanel) {
          scrollPanel(panel, scroll.lastScrollData);
        }
      });

      scroll.scrollingPanel = null;
    });

    scroll.scrollingPanel = scrolledPanel;
  }
};

interface Props {
  enabled?: boolean;
  children?: React.ReactNode;
}

export const ScrollSyncProvider = (props: Props) => {
  const { enabled, children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const ScrollSyncState = {
    state,
    addPanel: (panel: React.MutableRefObject<any>) => {
      if (panel.current && scroll.lastScrollData) {
        scrollPanel(panel.current, scroll.lastScrollData);
      }
      dispatch({ type: "ADD_PANEL", panel: panel.current });
    },
    removePanel: (panel: React.MutableRefObject<any>) => {
      dispatch({ type: "REMOVE_PANEL", panel: panel.current });
    },
    forceRecalculateScroll: () => {
      const { lastScrollData } = scroll;
      const { panels } = state;

      if (panels.length && lastScrollData) {
        panels.forEach(panel => scrollPanel(panel, lastScrollData));
      }
    }
  };

  useLayoutEffect(() => {
    scroll.panels = state.panels;
    scroll.enabled = enabled;

    if (state.panels.length && enabled) {
      ScrollSyncState.forceRecalculateScroll();
    }

    scroll.panels.forEach(panel => {
      panel.removeEventListener("scroll", scroll.callback);

      if (scroll.enabled) {
        panel.addEventListener("scroll", scroll.callback);
      }
    });
  }, [state.panels, enabled]);

  useLayoutEffect(() => {
    return () => {
      scroll.panels.forEach(panel => {
        panel.removeEventListener("scroll", scroll.callback);
      });
    };
  }, []);

  return (
    <ScrollSyncContext.Provider value={ScrollSyncState}>
      {children}
    </ScrollSyncContext.Provider>
  );
};

export default ScrollSyncContext;
