import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useDispatch } from "react-redux";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import clsx from "clsx";

import parseISO from "date-fns/parseISO";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { NONE } from "common/constants";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import ScrollSyncContext from "components/ScrollSync/ScrollSyncContext";
import { useRacehorse360Api } from "hooks/api";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { SortOrder } from "interfaces/SortOrder";
import {
  removeAssignCheckedId,
  setAssignCheckedIds,
  setExamCheckedIds,
  setPassCheckedIds,
  setSavingUnAssignIds,
  setSavingUndoQuickPassIds
} from "store/actions/vetWorkoutsPage";
import { isBeforeDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import { EColumn } from "./helper";
import RejectRequestDialog from "./RejectRequestDialog";
import { withClickBlocker } from "components/BlockableClickContext";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";
import ExamNote from "components/VetExamsTable/ExamNote";
import RowContent from "components/VetExamsTable/TableRow/RowContent";
import DateCell from "components/VetExamsTable/TableRow/RowContent/DateCell";
import TrainerCell from "components/VetExamsTable/TableRow/RowContent/TrainerCell";
import ExamStatusCell from "components/VetExamsTable/TableRow/RowContent/ExamStatusCell";
import FlagCell from "components/VetExamsTable/TableRow/RowContent/FlagCell";
import ExamCell from "components/VetExamsTable/TableRow/RowContent/ExamCell";
import ActionCellsWrapper from "components/VetExamsTable/TableRow/RowContent/ActionCellsWrapper";
import ActionCellCheckbox from "components/VetExamsTable/TableRow/RowContent/ActionCellsWrapper/ActionCellCheckbox";
import { getWorkoutRequestFlagPriority } from "components/VetExamsTable/TableRow/RowContent/FlagCell/helper";
import Row from "components/VetExamsTable/TableRow";
import {
  EExamTableStatus,
  IExamsTableRowData,
  ITableColumnsContent
} from "components/VetExamsTable/types";
import {
  EFlagPriority,
  examsRowDataMapper
} from "components/VetExamsTable/helper";
import TrainerCommentsPopover, {
  IPopoverItem
} from "components/VetExamsTable/TableRow/CommentsPopover/CommentsPopover";
import UnassignExamCell from "./UnassignExamCell";
import { commonSelectedFields } from "../options";
import { checkCurrentWorkoutsTableTab } from "../../helper";

import useStyles from "./styles";

interface IProps {
  index: number;
  columnsClassName: string;
  rowData: IExamsTableRowData;
  columns: string[];
  refetchData: (value?: boolean) => Promise<void>;
  onExamClick: (request: IExamsTableRowData) => void;
  onUpdateTableRows: (rows: IExamsTableRowData[]) => void;
}

const WorkoutRow = (props: IProps) => {
  const {
    index,
    rowData,
    columns,
    refetchData,
    columnsClassName,
    onExamClick,
    onUpdateTableRows
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useLoggedInUser();
  const scrollSyncState = useContext(ScrollSyncContext);
  const { isLocked, pullLocker } = useExamLockerContext();

  const {
    selectedTab,
    examCheckedIds,
    passCheckedIds,
    assignCheckedIds,
    savingCheckedIds,
    savingUndoQuickPassIds,
    savingUnAssignIds
  } = useAppSelector(state => state?.vetWorkoutsPage);

  const [isRowRejected, setIsRowRejected] = useState(false);
  const [currentRowData, setCurrentRowData] =
    useState<IExamsTableRowData>(rowData);
  const [trainerCommentsPopoverAnchor, setTrainerCommentsPopoverAnchor] =
    useState<HTMLElement>(null);

  const {
    eventId: workoutRequestId,
    eventDate: workoutRequestDate,
    eventFlag: workoutRequestFlag,
    hasEventComment: hasWorkoutRequestComment,
    eventRejectionNote: workoutRequestRejectionNote,
    eventFacility: workoutRequestFacility,
    eventCreationDate: workoutRequestCreationDate,
    eventStatus: workoutRequestStatus,
    examType,
    examStatus,
    examAssignedUser,
    examCreatedById,
    horse
  } = currentRowData;

  const { isRequestsTab, isExamsTab, isRejectedTab } =
    checkCurrentWorkoutsTableTab(selectedTab.value);
  const isExpiredRequest = isBeforeDate(
    parseISO(workoutRequestDate),
    new Date()
  );
  const isExamChecked = examCheckedIds?.includes(workoutRequestId);
  const isPassChecked = passCheckedIds?.includes(workoutRequestId);
  const isAssignChecked = assignCheckedIds?.includes(workoutRequestId);
  const isUndoQuickPassSaving =
    savingUndoQuickPassIds?.includes(workoutRequestId);

  const isCheckBoxDisabled =
    savingCheckedIds?.includes(workoutRequestId) ||
    (isExpiredRequest && !isExamsTab);

  const {
    useRevertQuickPassWorkoutRequestExam,
    useUnassignWorkoutRequestExam,
    useRevertWorkoutRequest,
    useCreateWorkoutRequestFlag,
    useEditWorkoutRequestFlag,
    useResetWorkoutRequestFlag,
    useSetWorkoutRequestExamReasonNote,
    useResetWorkoutRequestExamReasonNote,
    useListWorkoutRequestComment
  } = useRacehorse360Api();

  const {
    data: listWorkoutRequestComments,
    isLoading: isListWorkoutRequestCommentLoading
  } = useListWorkoutRequestComment(
    {
      query: {
        workoutRequestIds: [workoutRequestId],
        commentTypes: [
          racehorse360.WorkoutRequestCommentType
            .WORKOUT_REQUEST_COMMENT_TYPE_TRAINER_COMMUNICATION,
          racehorse360.WorkoutRequestCommentType
            .WORKOUT_REQUEST_COMMENT_TYPE_RACING_SECRETARY_COMMUNICATION
        ]
      },
      getOptions: {
        select: [
          "body",
          "updatedOn",
          "updatedBy.firstName",
          "updatedBy.lastName"
        ],
        orderBy: [`updatedOn ${SortOrder.DESC}`]
      }
    },
    {
      enabled: [trainerCommentsPopoverAnchor, workoutRequestId].every(Boolean)
    }
  );

  const preparedCommentData: IPopoverItem[] =
    listWorkoutRequestComments?.workoutRequestComments.map(comment => ({
      id: comment?.id,
      updatedOn: comment?.updatedOn,
      updatedBy: comment?.updatedBy,
      body: comment?.body
    }));

  const { mutateAsync: unassignExam } = useUnassignWorkoutRequestExam();
  const { mutateAsync: revertQuickPass } =
    useRevertQuickPassWorkoutRequestExam();
  const {
    mutateAsync: revertWorkoutRequest,
    isPending: isRevertWorkoutRequestPending
  } = useRevertWorkoutRequest();

  const { mutateAsync: createWorkoutRequestFlag } =
    useCreateWorkoutRequestFlag();
  const { mutateAsync: editWorkoutRequestFlag } = useEditWorkoutRequestFlag();
  const { mutateAsync: resetWorkoutRequestFlag } = useResetWorkoutRequestFlag();

  const {
    mutateAsync: setWorkoutRequestExamReasonNote,
    isPending: isSetWorkoutRequestExamReasonNoteLoading
  } = useSetWorkoutRequestExamReasonNote();

  const {
    mutateAsync: resetWorkoutRequestExamReasonNote,
    isPending: isResetWorkoutRequestExamReasonNoteLoading
  } = useResetWorkoutRequestExamReasonNote();

  const isExamReasonNoteLoading = [
    isSetWorkoutRequestExamReasonNoteLoading,
    isResetWorkoutRequestExamReasonNoteLoading
  ].some(Boolean);

  const isQuickPassType =
    examType === racehorse360.HorseExamType.HORSE_EXAM_TYPE_QUICK_PASS;

  const isRequestNotInFuture = [
    isBefore(new Date(), parseISO(workoutRequestDate)),
    isSameDay(new Date(), parseISO(workoutRequestDate))
  ].some(Boolean);

  const isExamNotInProgress = [
    workoutRequestStatus ===
      racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
    examStatus === racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED
  ].every(Boolean);

  const isCurrentUserAssigned = currentUser.rh360Id === examAssignedUser?.id;
  const isCurrentUserTrackVetAndCreator = [
    currentUser.isTrackVet,
    currentUser.rh360Id === examCreatedById
  ].every(Boolean);

  const canUserRevertExamStatus = [
    currentUser.isChiefVet,
    currentUser.isSeniorVet,
    isCurrentUserTrackVetAndCreator
  ].some(Boolean);

  const userHasAccess = [
    currentUser.isChiefVet,
    currentUser.isSeniorVet,
    isCurrentUserAssigned
  ].some(Boolean);

  const shouldShowFlagPriorityIndicator = [
    workoutRequestFlag,
    isExamsTab || isRequestsTab
  ].every(Boolean);

  const shouldShowFlagOnTrack = [
    isRequestsTab,
    horse.hasActualFlagOnTrack
  ].every(Boolean);

  const shouldShowExamReasonNote = [isRequestsTab, isExamsTab].some(Boolean);

  const shouldShowLastExamNote = [
    horse.lastCompletedExamWithNote,
    !isRejectedTab
  ].every(Boolean);

  const shouldShowExamsTablePPs = [isRequestsTab, horse].every(Boolean);

  const shouldShowRevertTypeButton = [
    isRequestNotInFuture,
    userHasAccess,
    isQuickPassType
  ].every(Boolean);

  const shouldShowRevertStatusButton = [
    !isAfter(new Date(), endOfDay(parseISO(workoutRequestDate))),
    canUserRevertExamStatus,
    isExamNotInProgress
  ].every(Boolean);

  const isUnassignDisabled = (): boolean => {
    const isAssignedToCurrentTrackVet = [
      currentUser.isTrackVet,
      currentUser.rh360Id === examAssignedUser?.id
    ].every(Boolean);
    const isCurrentChiefOrSeniorVet = [
      currentUser.isSeniorVet,
      currentUser.isChiefVet
    ].some(Boolean);
    const isRequested =
      examStatus === racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED;

    return (
      !(isCurrentChiefOrSeniorVet || isAssignedToCurrentTrackVet) ||
      !isRequested ||
      (isExpiredRequest && !isExamsTab)
    );
  };

  const isReassignDenied = (): boolean => {
    const isCurrentTrackVet = currentUser.isTrackVet;
    const isCurrentChiefOrSeniorVet =
      currentUser.isSeniorVet || currentUser.isChiefVet;
    const isAssignedToCurrentVet = currentUser.rh360Id === examAssignedUser?.id;
    const isRequested =
      examStatus === racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_REQUESTED;

    return (
      (!isCurrentTrackVet || !isRequested || isAssignedToCurrentVet) &&
      !isCurrentChiefOrSeniorVet
    );
  };

  const isExamLaunchDenied = (examAssignedUserId: string): boolean => {
    const isAssignedToCurrentTrackVet =
      currentUser.isTrackVet && currentUser.rh360Id === examAssignedUserId;
    const isCurrentChiefOrSeniorVet =
      currentUser.isSeniorVet || currentUser.isChiefVet;

    return (
      !(isAssignedToCurrentTrackVet || isCurrentChiefOrSeniorVet) ||
      isLocked ||
      (isExpiredRequest && !isExamsTab)
    );
  };

  const setExamChecked = (workoutRequestId: string, checked: boolean) => {
    if (checked) {
      dispatch(setExamCheckedIds([...examCheckedIds, workoutRequestId]));
    } else {
      dispatch(
        setExamCheckedIds(
          examCheckedIds.filter(
            examCheckedId => examCheckedId !== workoutRequestId
          )
        )
      );
    }
  };

  const setPassChecked = (workoutRequestId: string, checked: boolean) => {
    if (checked) {
      dispatch(setPassCheckedIds([...passCheckedIds, workoutRequestId]));
    } else {
      dispatch(
        setPassCheckedIds(
          passCheckedIds.filter(
            passCheckedId => passCheckedId !== workoutRequestId
          )
        )
      );
    }
  };

  const setAssignChecked = (workoutRequestId: string, checked: boolean) => {
    if (checked) {
      dispatch(setAssignCheckedIds([...assignCheckedIds, workoutRequestId]));
    } else {
      dispatch(
        setAssignCheckedIds(
          assignCheckedIds.filter(
            assignCheckedId => assignCheckedId !== workoutRequestId
          )
        )
      );
    }
  };

  const handleOpenTrainerCommentsPopover = event => {
    setTrainerCommentsPopoverAnchor(event.target);
  };

  const handleCloseTrainerCommentsPopover = () => {
    setTrainerCommentsPopoverAnchor(null);
  };

  const handleSaveWorkoutRequestComment = async (note: string) => {
    const workoutRequestWithNote = await setWorkoutRequestExamReasonNote({
      id: workoutRequestId,
      body: note.trim(),
      getOptions: {
        select: [
          "examReasonNote.id",
          "examReasonNote.body",
          "examReasonNote.notedOn",
          "examReasonNote.notedBy"
        ]
      }
    });

    handleChangeExamReasonNote(workoutRequestWithNote.examReasonNote);
  };

  const handleDeleteWorkoutRequestComment = async () => {
    await resetWorkoutRequestExamReasonNote({
      id: workoutRequestId
    });

    handleChangeExamReasonNote(null);
  };

  const handleChangeWorkoutRequestFlag = (
    eventFlag?: racehorse360.IWorkoutRequestFlag
  ) => {
    setCurrentRowData({ ...currentRowData, eventFlag });
    scrollSyncState.forceRecalculateScroll();
  };

  const handleChangePriority = async (
    event: ChangeEvent<HTMLInputElement>,
    hasFlag: boolean
  ) => {
    const priority = getWorkoutRequestFlagPriority(
      event.currentTarget.value as EFlagPriority
    );

    if (!hasFlag) {
      const createdFlag = await createWorkoutRequestFlag({
        workoutRequestId,
        priority,
        getOptions: {
          select: ["priority", "flaggedBy.lastName", "flaggedOn"]
        }
      });
      handleChangeWorkoutRequestFlag(createdFlag);

      return;
    }

    if (
      priority ===
      racehorse360.WorkoutRequestFlagPriority
        .WORKOUT_REQUEST_FLAG_PRIORITY_INVALID
    ) {
      await resetWorkoutRequestFlag({ workoutRequestId });
      handleChangeWorkoutRequestFlag(null);

      return;
    }

    const updatedFlag = await editWorkoutRequestFlag({
      workoutRequestId,
      priority,
      getOptions: {
        select: ["priority", "flaggedBy.lastName", "flaggedOn"]
      }
    });
    handleChangeWorkoutRequestFlag(updatedFlag);
  };

  const handleExamCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;

    setExamChecked(workoutRequestId, checked);

    if (checked) {
      setPassChecked(workoutRequestId, false);
    }
  };

  const handlePassCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;

    setPassChecked(workoutRequestId, checked);

    if (checked) {
      setExamChecked(workoutRequestId, false);
    }
  };

  const uncheckExamPass = (workoutRequestId: string) => {
    setExamChecked(workoutRequestId, false);
    setPassChecked(workoutRequestId, false);
  };

  const handleExamButtonClick = () => {
    pullLocker().then(response => {
      if (!response.data.isLocked) {
        onExamClick(currentRowData);
      }
    });
  };

  const handleUnAssignDialogConfirm = () => {
    dispatch(setSavingUnAssignIds([...savingUnAssignIds, workoutRequestId]));

    const clearUnAssignIds = () => {
      dispatch(
        setSavingUnAssignIds(
          savingUnAssignIds.filter(savingUnAssignId => {
            return savingUnAssignId !== workoutRequestId;
          })
        )
      );
    };

    unassignExam({
      id: workoutRequestId,
      getOptions: {
        select: [
          ...commonSelectedFields,
          ...selectedTab.additionalSelectedFields
        ]
      }
    })
      .then(res => {
        clearUnAssignIds();
        onUpdateTableRows([examsRowDataMapper(res)]);
      })
      .catch(error => {
        console.error("ERROR", error);
        clearUnAssignIds();
      });
  };

  const handleAssignCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setAssignChecked(workoutRequestId, checked);
  };

  const handleUndoQuickPassConfirm = () => {
    dispatch(
      setSavingUndoQuickPassIds([...savingUndoQuickPassIds, workoutRequestId])
    );

    const clearUndoQuickPassIds = () => {
      dispatch(
        setSavingUndoQuickPassIds(
          savingUndoQuickPassIds.filter(savingUndoQuickPassId => {
            return savingUndoQuickPassId !== workoutRequestId;
          })
        )
      );
    };

    revertQuickPass({
      id: workoutRequestId
    })
      .then(async () => {
        clearUndoQuickPassIds();
        await refetchData();
      })
      .catch(error => {
        console.error(error);
        clearUndoQuickPassIds();
      });
  };

  const handleChangeExamReasonNote = (
    examNote: racehorse360.IWorkoutRequestExamReasonNote
  ) => {
    const updatedRowData = { ...currentRowData, examNote };

    setCurrentRowData(updatedRowData);
  };

  const handleRevertWorkoutRequestConfirm = useCallback(() => {
    revertWorkoutRequest({
      id: workoutRequestId
    })
      .then(async () => {
        dispatch(removeAssignCheckedId(workoutRequestId));
        await refetchData();
      })
      .catch(error => console.error(error));
  }, []);

  const columnsContent: ITableColumnsContent = {
    [EColumn.TRAINER]: (
      <TrainerCell
        key={`${workoutRequestId}-trainer`}
        bold={isRequestsTab}
        trainerInfo={horse.trainer}
        shouldDisplayComments={
          hasWorkoutRequestComment && (isRequestsTab || isRejectedTab)
        }
        onCommentIconClick={handleOpenTrainerCommentsPopover}
      />
    ),
    [EColumn.WORK_DATE]: (
      <DateCell
        key={`${workoutRequestId}-work-date`}
        date={workoutRequestDate}
        bold={isRequestsTab}
      />
    ),
    [EColumn.REQUESTED]: (
      <DateCell
        key={`${workoutRequestId}-creation-date`}
        date={workoutRequestCreationDate}
        bold={isRequestsTab}
      />
    ),
    [EColumn.EXAM_STATUS]: (
      <ExamStatusCell
        key={`${workoutRequestId}-exam-status`}
        status={isExamNotInProgress && EExamTableStatus.PENDING}
        horseName={horse.name}
        eventName={"Workout Request"}
        eventDate={workoutRequestDate}
        eventTabName={"Requests"}
        shouldShowRevertButton={shouldShowRevertStatusButton}
        revertButtonDataTestId={"revertWorkoutRequestButton"}
        onConfirm={handleRevertWorkoutRequestConfirm}
        isLoading={isRevertWorkoutRequestPending}
        centered={true}
      />
    ),
    [EColumn.LAST_EXAM_DATE]: (
      <DateCell
        key={`${workoutRequestId}-last-exam-date`}
        date={horse.lastExamDate}
        bold={isRequestsTab}
      />
    ),
    [EColumn.ASSIGNED_VET]: (
      <TableCellWrapper
        key={`${workoutRequestId}-assigned-vet`}
        fadedOut={!examAssignedUser}
      >
        <div className={classes.ellipsis}>
          {examAssignedUser ? getPersonFullName(examAssignedUser) : NONE}
        </div>
      </TableCellWrapper>
    ),
    [EColumn.UNASSIGN]: (
      <UnassignExamCell
        key={`${workoutRequestId}-unassign`}
        rowData={currentRowData}
        onConfirm={handleUnAssignDialogConfirm}
        disabled={isUnassignDisabled()}
      />
    ),
    [EColumn.EXAM]: (
      <ExamCell
        key={`${workoutRequestId}-exam`}
        shouldShowButton={Boolean(examAssignedUser?.id)}
        disabledButton={isExamLaunchDenied(examAssignedUser?.id)}
        onExamButtonClick={handleExamButtonClick}
      />
    ),
    [EColumn.EXAM_TYPE]: (
      <ExamStatusCell
        key={`${workoutRequestId}-exam-type`}
        status={isQuickPassType && EExamTableStatus.QUICK_PASSED}
        horseName={horse.name}
        eventName={"Workout Request"}
        eventDate={workoutRequestDate}
        eventTabName={"Requests"}
        shouldShowRevertButton={shouldShowRevertTypeButton}
        revertButtonDataTestId={"revertWorkoutRequestButton"}
        onConfirm={handleUndoQuickPassConfirm}
        isLoading={isUndoQuickPassSaving}
      />
    ),
    [EColumn.FLAG_REQUEST]: (
      <FlagCell
        key={`${workoutRequestId}-flag-request`}
        flag={workoutRequestFlag}
        facility={workoutRequestFacility}
        onChangePriority={handleChangePriority}
      />
    ),
    [EColumn.IMAGE_DIAGNOSTICS_REQUIRED]: (
      <TableCellWrapper key={`${workoutRequestId}-diagnostics`} centered>
        {workoutRequestRejectionNote?.isImagingDiagnosticsRequired
          ? "Yes"
          : "No"}
      </TableCellWrapper>
    )
  };

  const renderRequestMenuButtons = () => (
    <ActionCellsWrapper
      className={clsx({
        [classes.rowExamChecked]: isExamChecked,
        [classes.rowPassChecked]: isPassChecked,
        [classes.rowRejected]: isRowRejected,
        [classes.rowOdd]: index % 2
      })}
    >
      <ActionCellCheckbox
        className={clsx({ [classes.menuButtonExamChecked]: isExamChecked })}
        checked={isExamChecked}
        isLoading={isCheckBoxDisabled}
        onChange={handleExamCheckChange}
        dataTestId={"examCheckbox"}
      />
      <ActionCellCheckbox
        className={clsx({ [classes.menuButtonPassChecked]: isPassChecked })}
        checked={isPassChecked}
        isLoading={isCheckBoxDisabled}
        onChange={handlePassCheckChange}
        dataTestId={"quickPassCheckbox"}
      />
      <RejectRequestDialog
        workoutRequestId={workoutRequestId}
        horse={horse}
        isRowRejected={isRowRejected}
        setIsRowRejected={setIsRowRejected}
        disabled={isCheckBoxDisabled}
        uncheckExamPass={uncheckExamPass}
      />
    </ActionCellsWrapper>
  );

  const renderExamMenuButtons = () => (
    <ActionCellsWrapper
      className={clsx({
        [classes.rowAssignChecked]: isAssignChecked,
        [classes.rowOdd]: index % 2
      })}
    >
      <ActionCellCheckbox
        className={clsx({ [classes.menuButtonAssignChecked]: isAssignChecked })}
        checked={isAssignChecked}
        isLoading={isCheckBoxDisabled || isReassignDenied()}
        onChange={handleAssignCheckChange}
      />
    </ActionCellsWrapper>
  );

  const actionCells = (
    <>
      {isRequestsTab && renderRequestMenuButtons()}
      {isExamsTab && renderExamMenuButtons()}
    </>
  );

  const examReasonNoteContent = shouldShowExamReasonNote && (
    <ExamNote
      index={index}
      additionalDesktopClassNames={clsx({
        [classes.rowExamChecked]: isExamChecked,
        [classes.rowPassChecked]: isPassChecked,
        [classes.rowAssignChecked]: isAssignChecked
      })}
      rowData={currentRowData}
      isLoading={isExamReasonNoteLoading}
      readOnly={isExamsTab}
      onSaveComment={handleSaveWorkoutRequestComment}
      onDeleteComment={handleDeleteWorkoutRequestComment}
    />
  );

  useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  return (
    <>
      <Row
        index={index}
        rowContainerClassName={clsx({
          [classes.rowExamChecked]: isExamChecked,
          [classes.rowPassChecked]: isPassChecked,
          [classes.rowAssignChecked]: isAssignChecked,
          [classes.rowRejected]: isRowRejected
        })}
        rowContentClassName={columnsClassName}
        rowData={currentRowData}
        examNoteContent={examReasonNoteContent}
        actionCellsContent={actionCells}
        shouldShowFlagPriorityIndicator={shouldShowFlagPriorityIndicator}
        shouldShowFlagOnTrack={shouldShowFlagOnTrack}
        shouldShowLastExamNote={shouldShowLastExamNote}
        shouldShowExamsTablePPs={shouldShowExamsTablePPs}
        shouldShowRejectionNote={isRejectedTab}
      >
        <RowContent
          rowData={currentRowData}
          columns={columns}
          bold={isRequestsTab}
          customColumnsContent={columnsContent}
          extenderFitsTwoColumns={isRequestsTab}
          extenderFitsOneColumns={isExamsTab}
        />
      </Row>

      {Boolean(trainerCommentsPopoverAnchor) && (
        <TrainerCommentsPopover
          anchorElement={trainerCommentsPopoverAnchor}
          onClose={handleCloseTrainerCommentsPopover}
          isLoadingData={isListWorkoutRequestCommentLoading}
          data={preparedCommentData}
        />
      )}
    </>
  );
};

export default React.memo(withClickBlocker<IProps>(WorkoutRow));
