import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useAppSelector } from "hooks/redux/useAppSelector";
import { setSelectedPreRaceFacility } from "store/actions/preRaceScreeningPage";
import ErrorBoundary from "components/ErrorBoundary";
import AppSearch, { AppSearchType } from "components/AppSearch/AppSearch";
import FacilitySelect from "components/FacilitySelect";
import PickerFilter from "./PickerFilter";

import useStyles from "./styles";

const PreRaceScreeningPageHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedFacility = useAppSelector(
    state => state.preRaceScreeningPage.selectedFacility
  );

  const handleFacilityChange = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setSelectedPreRaceFacility(facility));
    },
    [dispatch]
  );

  return (
    <div className={classes.pageHeader}>
      <div className={classes.primaryFilter}>
        <div className={classes.facility}>
          <ErrorBoundary>
            <FacilitySelect
              selectedFacility={selectedFacility}
              onChange={handleFacilityChange}
            />
          </ErrorBoundary>
        </div>

        <PickerFilter className={classes.pickerFilterMobile} />
      </div>

      <div className={classes.secondaryFilter}>
        <div className={classes.searchFilter}>
          <ErrorBoundary>
            <AppSearch
              className={classes.search}
              placeholder={"Search Horses or Trainers"}
              searchType={AppSearchType.HORSES_AND_TRAINERS}
            />
          </ErrorBoundary>
        </div>

        <PickerFilter className={classes.pickerFilterDesktop} />
      </div>
    </div>
  );
};

export default React.memo(PreRaceScreeningPageHeader);
