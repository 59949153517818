import React from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { createDataTestAttr } from "../../utils/data-test-attributes-utils";
import { checkSortingType } from "utils/sort-utils";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "../BlockableClickContext";

interface Props {
  className?: string;
  children?: React.ReactNode;
  align?: "left" | "right";
  columnName: string;
  sortDirection?: SortOrder;
  onSort?: (columnName: string, sortDirection: SortOrder) => void;
}

const SortLabel: React.FC<Props & WithClickBlockerProps> = React.memo(props => {
  const {
    className,
    children,
    columnName,
    sortDirection,
    onSort,
    handleBlockableClick
  } = props;
  const align = props.align || "right";
  const { isAsc, isDesc } = checkSortingType(sortDirection);
  const dataTestAttr = sortDirection
    ? createDataTestAttr(`${children} column header ${sortDirection}`)
    : createDataTestAttr(`${children} column header`);

  const classes = useStyles();

  const handleClick = () => {
    if (onSort) {
      const newSortDirection =
        !sortDirection || isDesc ? SortOrder.ASC : SortOrder.DESC;

      onSort(columnName, newSortDirection);
    }
  };

  return (
    <Button
      className={clsx(classes.component, className)}
      onClick={() => handleBlockableClick(handleClick)}
      data-test-id={dataTestAttr}
    >
      {align === "right" && <div className={classes.content}>{children}</div>}
      <div className={classes.icons}>
        <div className={clsx(classes.iconUp, { [classes.iconSort]: isAsc })}>
          <ArrowDropUpIcon />
        </div>
        <div className={clsx(classes.iconDown, { [classes.iconSort]: isDesc })}>
          <ArrowDropDownIcon />
        </div>
      </div>
      {align === "left" && <div className={classes.content}>{children}</div>}
    </Button>
  );
});

export default withClickBlocker(SortLabel);
