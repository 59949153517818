import React, { useContext, useLayoutEffect, useRef } from "react";

import ScrollSyncContext from "../ScrollSyncContext";

interface Props {
  className?: string;
  children?: React.ReactNode;
  onScroll?: (event) => void;
}

const ScrollSync = (props: Props) => {
  const { className, children } = props;
  const ref = useRef(null);
  const scrollSyncState = useContext(ScrollSyncContext);

  useLayoutEffect(() => {
    scrollSyncState.addPanel(ref);
    return () => {
      scrollSyncState.removePanel(ref);
    };
  }, []);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};

export default ScrollSync;
