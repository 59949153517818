import React, { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Refresh from "@material-ui/icons/Refresh";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useClickBlockerContext } from "components/BlockableClickContext";
import CustomSelect from "components/CustomSelect";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import FormBlock from "components/FormBlock";
import GreenCheckbox from "components/GreenCheckbox";
import HideMenu from "components/Icons/HideMenu";
import Loader from "components/Loader";
import OverlayPage from "components/OverlayPage";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import {
  IWithScrollToErrorProps,
  WithScrollToError
} from "hocs/WithScrollToError";
import { useRacehorse360Api } from "hooks/api";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { SortOrder } from "interfaces/SortOrder";
import { EModalConfirmationTypes } from "interfaces/VetExamState";
import { HORSE_EXAM_OPTIONS } from "pages/HorseDetailsPage/Vet/helper";
import { setModalState, setModalType } from "store/actions/vetExam";
import { currentTimeZoneString, transformViewDate } from "utils/date-utils";
import ExamDetailsList from "./ExamDetailsList/ExamDetailsList";
import ExamFormHeader from "./ExamFormHeader";
import ExamResults from "./ExamResults";
import {
  checkSelectingExamFormFields,
  getExamResult,
  getVetExamRequestState,
  getCombinedLocalStateExam,
  getResetLocalStateExam
} from "./helper";
import HorseInfoContainer from "./HorseInfoContainer";
import JogInformation from "./JogInformation";
import { EVetExamFields, examReasons } from "./options";
import SubmitExamModal from "./SubmitExamModal";
import VetExamModal from "./VetExamModal";
import { useVetExamContext } from "./VetExamProvider/VetExamProvider";

import useStyles from "./styles";

interface IProps {
  refetchData: () => void;
  onClose: () => void;
}

const ERROR_ANCHOR = "fieldErrorMessage";

const VetExams: React.FC<IProps & IWithScrollToErrorProps> = props => {
  const { onClose, refetchData, scrollToFirstError } = props;

  const { currentUser } = useLoggedInUser();
  const classes = useStyles();
  const dispatchToRedux = useDispatch();
  const { lockExam, unlockExam } = useExamLockerContext();
  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();
  const { currentExam } = useVetExamContext();
  const { modalState, modalType } = useAppSelector(state => ({
    ...state.vetExam
  }));
  const { showErrorSnack } = useSnackbar();
  const {
    values,
    setFieldValue,
    setValues,
    validateForm,
    setShouldValidateOnChange
  } = useVetExamContext();
  const reason = values?.[EVetExamFields.REASON];
  const result = values?.[EVetExamFields.RESULT];

  const [shouldUseLastExamEntry, setShouldUseLastExamEntry] = useState(false);
  const [isShowHorseInfoDesktop, setIsShowHorseInfoDesktop] = useState(false);
  const [isShowHorseInfoMobile, setIsShowHorseInfoMobile] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const {
    useListWorkoutRequests,
    usePullRelevantHorseWarningMessage,
    useCancelHorseExam,
    useCompleteHorseExam,
    useEditHorseExam,
    useListHorseExams
  } = useRacehorse360Api();

  //WorkoutsExams
  const pastExamsQuery: racehorse360.IHorseExamFilter = {
    horseIds: [currentExam?.horse?.id],
    statuses: [racehorse360.HorseExamStatus.HORSE_EXAM_STATUS_COMPLETED],
    horseExamTypes: [racehorse360.HorseExamType.HORSE_EXAM_TYPE_FULL_EXAM]
  };
  const { data: pastExamsCountData } = useListHorseExams(
    {
      query: pastExamsQuery,
      pagingOptions: {
        maxResults: 1,
        includeSummary: true
      }
    },
    {
      enabled: Boolean(currentExam?.horse?.id)
    }
  );
  const pastExamsCount = pastExamsCountData?.pagingInfo?.totalResults || 0;

  const { data: pastExamsData, isLoading: isPastExamsLoading } =
    useListHorseExams(
      {
        query: pastExamsQuery,
        getOptions: {
          select: HORSE_EXAM_OPTIONS,
          orderBy: [`completedDate ${SortOrder.DESC}`]
        },
        pagingOptions: {
          maxResults: pastExamsCount
        }
      },
      {
        enabled: pastExamsCount > 0
      }
    );
  const completedExams = pastExamsData?.horseExams || [];

  //WorkoutRequest requests
  const {
    data: upcomingWorkoutRequests,
    isLoading: isUpcomingWorkoutRequestsLoading
  } = useListWorkoutRequests(
    {
      query: {
        horseIds: currentExam?.horse.id ? [currentExam?.horse.id] : [],
        date: {
          range: {
            relative: {
              // Today and later
              startDate: { value: 0 },
              timezone: currentTimeZoneString()
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      getOptions: {
        select: [
          "date",
          "facility.name",
          "horse.trainer.firstName",
          "horse.trainer.middleName",
          "horse.trainer.lastName",
          "createdOn"
        ],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(currentExam?.horse.id)
    }
  );
  const nextWorkoutRequest = upcomingWorkoutRequests?.workoutRequests[0];

  //WarningMessage requests
  const {
    data: horseWarningMessageData,
    isFetching: isPullRelevantHorseWarningMessageFetching,
    refetch: refetchHorseWarningMessage
  } = usePullRelevantHorseWarningMessage(
    {
      horseId: currentExam?.horse?.id
    },
    {
      enabled: Boolean(currentExam?.horse?.id)
    }
  );
  const warningMessage: racehorse360.IRelevantHorseWarningMessage =
    horseWarningMessageData?.relevantHorseWarningMessage;

  const { mutateAsync: saveExam } = useEditHorseExam();
  const { mutateAsync: cancelExam } = useCancelHorseExam({});
  const { mutateAsync: completeExam } = useCompleteHorseExam({});

  const closeSubmitModal = () => {
    setIsSubmitModalOpen(false);
  };

  const openCloseOrPauseModal = () => {
    dispatchToRedux(setModalState(true));
    dispatchToRedux(setModalType(EModalConfirmationTypes.PAUSE_OR_CLOSE));
  };

  const openResetModal = () => {
    dispatchToRedux(setModalState(true));
    dispatchToRedux(setModalType(EModalConfirmationTypes.RESET));
  };

  const lockNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => openCloseOrPauseModal());
  };

  const unLockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  const closeVetExamSavingState = () => {
    saveExam({
      horseExam: getVetExamRequestState(currentExam, values)
    }).then(() => {
      refetchData();
      onClose();
    });
  };

  const handleUseLastExamEntryChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!completedExams?.length) return;

    setShouldUseLastExamEntry(event.target.checked);

    if (event.target.checked) {
      setValues(getCombinedLocalStateExam(currentExam, completedExams[0]));
    } else {
      setValues(getResetLocalStateExam(currentExam));
    }
  };

  const handleSubmit = () => {
    validateForm()
      .then(errorsObject => {
        const hasErrors = Boolean(Object.keys(errorsObject).length);

        if (hasErrors) {
          scrollToFirstError(ERROR_ANCHOR);
        } else {
          setIsSubmitModalOpen(true);
        }

        setShouldValidateOnChange(hasErrors);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDiscardExam = () => {
    cancelExam({ id: currentExam.id }).then(() => {
      refetchData();
      onClose();
    });
  };

  const handleCompleteExam = (date: Date) => {
    const completedDate = transformViewDate(
      date.toISOString(),
      null,
      "yyyy-MM-dd HH:mm:ss"
    );

    completeExam({
      horseExam: getVetExamRequestState(currentExam, values),
      completedDate,
      timezone: currentTimeZoneString()
    })
      .then(() => {
        refetchData();
        onClose();
      })
      .catch((error: Error) => {
        showErrorSnack(error.message);
      });
  };

  const handleResetExam = () => {
    setValues(getResetLocalStateExam(currentExam));
    setShouldUseLastExamEntry(false);
  };

  const handleCloseModal = () => {
    dispatchToRedux(setModalState(false));
    dispatchToRedux(setModalType(null));
  };

  const handleChangeExamReason = (value: string) => {
    setFieldValue(EVetExamFields.REASON, value);
    lockExam();
  };

  const handleChangeRightPanelState = () => {
    setIsShowHorseInfoDesktop(!isShowHorseInfoDesktop);
  };

  const handleCloseHorseInfoMobile = () => {
    setIsShowHorseInfoMobile(false);
  };

  const handleOpenHorseInfoMobile = () => {
    setIsShowHorseInfoMobile(true);
  };

  const handlePauseAndCloseClick = () => {
    closeVetExamSavingState();
  };

  const renderUseLastExamEntryCheckbox = () => {
    const lastExam = completedExams?.[0];

    const checkboxCaption = lastExam ? (
      <>
        Use Last Exam Entry&nbsp;
        <span className={classes.italic}>
          (
          {transformViewDate(
            lastExam.completedDate,
            lastExam.facility.timezone,
            "M/d/yy"
          )}
          )
        </span>
      </>
    ) : (
      <span className={classes.italic}>No Past Exams</span>
    );

    return (
      <div className={classes.useLastExamEntryCheckboxContainer}>
        <label
          className={clsx(classes.useLastExamEntryCheckboxLabel, {
            [classes.noPastExams]: !lastExam
          })}
        >
          {checkboxCaption}
          <GreenCheckbox
            checked={shouldUseLastExamEntry}
            disabled={!lastExam}
            onChange={handleUseLastExamEntryChange}
          />
        </label>
      </div>
    );
  };

  const renderActionsButtons = () => {
    return (
      <div className={classes.actionButtons}>
        <Button
          color="primary"
          className={clsx(classes.button, classes.pauseAndClose)}
          variant="contained"
          onClick={handlePauseAndCloseClick}
          data-test-id={"pauseAndCloseButton"}
        >
          Pause &#38; Close
        </Button>
        <Button
          color="primary"
          className={clsx(classes.button, classes.submit)}
          variant="contained"
          onClick={handleSubmit}
          data-test-id={"submitExamButton"}
        >
          Submit Exam
        </Button>
      </div>
    );
  };

  useEffect(() => {
    lockNavigation();
    lockExam();
    return () => {
      unLockNavigation();
      unlockExam();
    };
  }, []);

  const isLoading = [
    isUpcomingWorkoutRequestsLoading,
    isPullRelevantHorseWarningMessageFetching,
    isPastExamsLoading,
    !values
  ].some(Boolean);

  const isActiveReset = checkSelectingExamFormFields(values);

  return (
    <OverlayPage
      className={classes.overlayPage}
      onClose={openCloseOrPauseModal}
    >
      {isLoading ? (
        <Loader className={classes.pageLoader} />
      ) : (
        <>
          <DialogTitle className={classes.mobileHeader}>
            <Button
              className={classes.closeButton}
              onClick={openCloseOrPauseModal}
            >
              Close
            </Button>
            <span className={classes.mobileTitle}>Vet Exam</span>
            <IconButton disabled={!isActiveReset} onClick={openResetModal}>
              <Refresh />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.root}>
            <IconButton
              className={clsx(classes.hideMenuButton, {
                ["revert"]: isShowHorseInfoDesktop
              })}
              onClick={handleChangeRightPanelState}
            >
              <HideMenu />
            </IconButton>

            <div
              className={clsx(classes.vetExamForm, {
                ["showRightPanel"]: isShowHorseInfoDesktop
              })}
            >
              <ExamFormHeader
                horse={currentExam?.horse}
                isActiveReset={isActiveReset}
                isShowHorseInfoDesktop={isShowHorseInfoDesktop}
                onOpenHorseInfoMobile={handleOpenHorseInfoMobile}
                onOpenResetModal={openResetModal}
                warningMessage={warningMessage}
                isPullRelevantHorseWarningMessageFetching={
                  isPullRelevantHorseWarningMessageFetching
                }
                refetchHorseWarningMessage={refetchHorseWarningMessage}
              />

              <div className={classes.scrollableContainer}>
                {renderUseLastExamEntryCheckbox()}

                <FormBlock title="Reason for Exam">
                  <CustomSelect
                    className={classes.reasonsSelect}
                    options={examReasons}
                    label="Reason"
                    inputLabel={"Select Reason"}
                    selectedValue={reason}
                    onChangeSelectedValue={handleChangeExamReason}
                    dataTestLabel={"examReasonDropdown"}
                    shouldSort={true}
                  />
                </FormBlock>

                <JogInformation />

                <ExamDetailsList />

                <ExamResults />

                {renderActionsButtons()}
              </div>
            </div>

            <HorseInfoContainer
              horse={currentExam?.horse}
              workoutRequest={nextWorkoutRequest}
              isShowHorseInfoDesktop={isShowHorseInfoDesktop}
              isShowHorseInfoMobile={isShowHorseInfoMobile}
              onCloseHorseInfoMobile={handleCloseHorseInfoMobile}
              completedExams={completedExams}
            />

            {modalState && (
              <VetExamModal
                onCloseModal={handleCloseModal}
                closeVetExamSavingState={closeVetExamSavingState}
                resetExam={handleResetExam}
                discardExam={handleDiscardExam}
                type={modalType}
                horseName={currentExam?.horse?.name}
              />
            )}

            {isSubmitModalOpen && (
              <SubmitExamModal
                onCloseModal={closeSubmitModal}
                completeExam={handleCompleteExam}
                horseName={currentExam?.horse?.name}
                examResult={getExamResult(Number(result))}
                assignedVetId={
                  currentExam?.assignedUserId || currentUser.rh360Id
                }
              />
            )}
          </DialogContent>
        </>
      )}
    </OverlayPage>
  );
};

export default React.memo(WithScrollToError<IProps>(VetExams));
