import React, { useState } from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import HorseWorkoutComment from "./HorseWorkoutComment";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

export interface Props {
  workout: racehorse360.IWorkoutRequest;
  onEditStart: (commentId: string | number) => void;
  onEditFinish: () => void;
}

const HorseWorkoutComments = (props: Props) => {
  const { onEditStart, onEditFinish, workout } = props;
  const classes = useStyles();
  const workoutId = workout.id;
  const horse = workout.horse;
  const [editCommentId, setEditCommentId] = useState<string>(null);

  const {
    useDeleteWorkoutRequestComment,
    useCreateWorkoutRequestComment,
    useEditWorkoutRequestComment,
    useListWorkoutRequestComment
  } = useRacehorse360Api();

  const {
    isLoading: isListCommentsLoading,
    refetch: refetchWorkoutComments,
    data
  } = useListWorkoutRequestComment(
    {
      query: {
        workoutRequestIds: [workoutId]
      },
      getOptions: {
        select: [
          "id",
          "body",
          "createdOn",
          "updatedOn",
          "createdBy.firstName",
          "createdBy.lastName"
        ],
        orderBy: [`updatedOn ${SortOrder.DESC}`]
      }
    },
    {
      enabled: Boolean(workoutId)
    }
  );

  const workoutComments = data?.workoutRequestComments;

  const {
    mutateAsync: createWorkoutRequestComment,
    isPending: isLoadingCreateComment
  } = useCreateWorkoutRequestComment();

  const {
    mutateAsync: editWorkoutRequestComment,
    isPending: isLoadingEditComment
  } = useEditWorkoutRequestComment();

  const { mutateAsync: deleteWorkoutRequestComment } =
    useDeleteWorkoutRequestComment();

  const handleEditStart = (commentId: string | number) => {
    onEditStart(commentId);
  };

  const handleEditCancel = () => {
    onEditFinish();
  };

  const handleDeleteClick = (commentId: string) => {
    deleteWorkoutRequestComment({
      id: commentId
    })
      .then(() => {
        onEditFinish();
        return refetchWorkoutComments();
      })
      .catch(error => console.log(error));
  };

  const handleSave = (commentId: string, textComment: string) => {
    setEditCommentId(commentId);
    if (commentId && textComment) {
      editWorkoutRequestComment({
        id: commentId,
        body: textComment
      })
        .then(() => {
          onEditFinish();
          return refetchWorkoutComments();
        })
        .catch(error => console.log(error));
    } else {
      createWorkoutRequestComment({
        workoutRequestId: workoutId,
        body: textComment
      })
        .then(() => {
          onEditFinish();
          return refetchWorkoutComments();
        })
        .catch(error => console.log(error));
    }
  };

  if (isListCommentsLoading) {
    return <Loader />;
  }

  const renderUnsavedChangesPromptContent = () => (
    <>
      You have unsaved changes to the workout comments for{" "}
      <span className={classes.bold}>{horse?.name}</span>. Would you like to
      keep or discard these changes?
    </>
  );

  return (
    <>
      <HorseWorkoutComment
        onSave={handleSave}
        onEditStart={handleEditStart}
        onCancel={handleEditCancel}
        onDelete={handleDeleteClick}
        isLoadingCreateComment={isLoadingCreateComment}
        unsavedChangesPrompt={renderUnsavedChangesPromptContent()}
      />

      {workoutComments?.map(comment => (
        <HorseWorkoutComment
          key={comment.id}
          comment={comment}
          onEditStart={handleEditStart}
          onCancel={handleEditCancel}
          onSave={handleSave}
          onDelete={handleDeleteClick}
          isLoadingEditComment={
            isLoadingEditComment && editCommentId === comment.id
          }
          unsavedChangesPrompt={renderUnsavedChangesPromptContent()}
        />
      ))}
    </>
  );
};

export default HorseWorkoutComments;
