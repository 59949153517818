import React from "react";

import SortLabel from "components/SortLabel";
import { IHeaderCell } from "../../types";

type Props = Pick<IHeaderCell, "title" | "sortableCell">;

const HeaderCellContent = (props: Props) => {
  const { title, sortableCell } = props;

  if (sortableCell) {
    const { name, sortOrder, sortHandler } = sortableCell;

    return (
      <SortLabel
        columnName={name}
        sortDirection={sortOrder}
        onSort={sortHandler}
      >
        {title}
      </SortLabel>
    );
  }

  return <>{title}</>;
};

export default React.memo(HeaderCellContent);
