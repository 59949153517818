import React, { ChangeEvent } from "react";
import clsx from "clsx";
import { shallowEqual, useDispatch } from "react-redux";

import { setShowPassedEntriesCheckboxState } from "store/actions/preRaceScreeningPage";
import { useAppSelector } from "hooks/redux/useAppSelector";
import Checkbox from "components/Checkbox/Checkbox";
import { checkCurrentRaceEntriesTableTab } from "../../helper";

import useStyles from "./styles";

const ENTRIES_TAB_INFO = `
Changes are automatically saved and can be reverted on the results tab`;
const SHOW_PASSED_ENTRIES_CHECKBOX_LABEL = "Show Passed Entries";
const SHOW_PASSED_ENTRIES_CHECKBOX_NAME = "passed";

interface IProps {
  className?: string;
}

const TabFilters = (props: IProps) => {
  const { className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedTab, showPassedEntriesState } = useAppSelector(
    state => ({
      selectedTab: state.preRaceScreeningPage.selectedTab,
      showPassedEntriesState: state.preRaceScreeningPage.showPassedEntries
    }),
    shallowEqual
  );

  const { isEntriesTab, isResultsTab, isRaceDayTab } =
    checkCurrentRaceEntriesTableTab(selectedTab.value);

  const handleChangeShowPassedEntriesCheckbox = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setShowPassedEntriesCheckboxState(event.target.checked));
  };

  const shouldShowPassedEntriesCheckbox = [isResultsTab, isRaceDayTab].some(
    Boolean
  );

  return (
    <div className={clsx(className, classes.tabFilters)}>
      {isEntriesTab && (
        <span className={classes.tabInfo}>{ENTRIES_TAB_INFO}</span>
      )}
      {shouldShowPassedEntriesCheckbox && (
        <Checkbox
          classes={{
            root: classes.checkbox,
            colorSecondary: classes.secondaryColor,
            checked: classes.checked
          }}
          isChecked={showPassedEntriesState}
          startLabel={SHOW_PASSED_ENTRIES_CHECKBOX_LABEL}
          value={SHOW_PASSED_ENTRIES_CHECKBOX_NAME}
          name={SHOW_PASSED_ENTRIES_CHECKBOX_NAME}
          onChange={handleChangeShowPassedEntriesCheckbox}
        />
      )}
    </div>
  );
};

export default React.memo(TabFilters);
