import React, { useState, useRef } from "react";
import DateFnsUtils from "@date-io/date-fns";
import getWeeksInMonth from "date-fns/getWeeksInMonth";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import BackArrow from "@material-ui/icons/ArrowBackIos";

import Calendar4x3 from "components/Icons/Calendar4x3";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import { EExamResult } from "../helper";

import useStyles from "./styles";

interface IProps {
  onCloseModal: () => void;
  completeExam: (completedDate: Date) => void;
  horseName: string;
  assignedVetId: string;
  examResult: EExamResult;
}

enum EMonthType {
  FIVE_WEEK_MONTH = 5,
  SIX_WEEK_MONTH = 6
}

const DATE_FORMAT = "MM/dd/yyyy";

const SubmitExamModal = (props: IProps) => {
  const {
    onCloseModal,
    horseName = "Horse Name",
    examResult,
    completeExam,
    assignedVetId
  } = props;

  const classes = useStyles();
  const anchorEl = useRef();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [tempDate, setTempDate] = useState<Date>(selectedDate);
  const [monthType, setMonthType] = useState<EMonthType>(
    getWeeksInMonth(selectedDate)
  );

  const { useGetUser } = useRacehorse360Api();

  const { data: assignedVetUser, isLoading: isGetUserLoading } = useGetUser(
    {
      id: assignedVetId,
      getOptions: {
        select: ["firstName", "lastName"]
      }
    },
    {
      enabled: Boolean(assignedVetId)
    }
  );

  const assignedVet = getPersonFullName({
    firstName: assignedVetUser?.firstName,
    lastName: assignedVetUser?.lastName
  });

  const dialogTitle = isCalendarOpen ? horseName : "Confirm Exam Submission";
  const hasNoChanges = tempDate === selectedDate;
  const isSixWeekMonth = monthType === EMonthType.SIX_WEEK_MONTH;

  const handleOpenCalendar = () => {
    setTempDate(selectedDate);
    setIsCalendarOpen(true);
  };

  const handleChangeDate = (date: Date) => {
    setTempDate(date);
  };

  const handleClickSuccessButton = () => {
    completeExam(selectedDate);
    onCloseModal();
  };

  const handleClickConfirmDateButton = () => {
    setIsCalendarOpen(false);
    setSelectedDate(tempDate);
  };

  const handleClickBackButton = () => {
    setIsCalendarOpen(false);
  };

  const handleChangeMonth = date => {
    setMonthType(getWeeksInMonth(date));
  };

  const renderContent = () => {
    if (isGetUserLoading) return <Loader overlay />;

    const selectedDateText = hasNoChanges
      ? "No Date Selected"
      : transformViewDate(tempDate.toISOString(), null, DATE_FORMAT);

    return (
      <>
        {!isCalendarOpen && (
          <>
            <div className={classes.note}>
              Please review the exam highlight information below for accuracy:
            </div>
            <div className={classes.contentLabel}>Horse Name</div>
            <div className={classes.contentValue}>{horseName}</div>
            <div className={classes.contentLabel}>Exam Decision</div>
            <div className={classes.contentValue}>{examResult}</div>
            <div className={classes.contentLabel}>Vet</div>
            <div className={classes.contentValue}>{assignedVet}</div>
            <div className={classes.contentLabel}>Exam Date</div>
          </>
        )}

        <div ref={anchorEl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={clsx(classes.datePickerField, {
                calendarOpen: isCalendarOpen,
                sixWeekMonth: isSixWeekMonth
              })}
              onOpen={handleOpenCalendar}
              onMonthChange={handleChangeMonth}
              open={isCalendarOpen}
              value={selectedDate}
              onChange={handleChangeDate}
              size={"small"}
              variant={"inline"}
              inputVariant={"outlined"}
              disableFuture={true}
              disableToolbar
              format={DATE_FORMAT}
              maxRows={2}
              PopoverProps={{
                anchorEl: anchorEl.current,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right"
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "right"
                },
                PaperProps: {
                  classes: {
                    elevation8: classes.paperElevation8
                  }
                },
                classes: {
                  root: classes.popoverRoot,
                  paper: clsx(classes.calendarPaper, {
                    sixWeekMonth: isSixWeekMonth
                  })
                }
              }}
              InputProps={{
                endAdornment: <Calendar4x3 className={classes.calendarIcon} />
              }}
            />
          </MuiPickersUtilsProvider>
        </div>

        {isCalendarOpen && (
          <div className={classes.selectedDate}>
            <div className={classes.selectedDateText}>
              Select a New Exam Date
            </div>
            <div
              className={clsx(classes.selectedDateText, {
                hasNoChanges: hasNoChanges
              })}
            >
              {selectedDateText}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderActionsButtons = () => {
    if (isCalendarOpen) {
      return (
        <>
          <Button
            className={classes.calendarButton}
            variant={"outlined"}
            onClick={handleClickBackButton}
            startIcon={<BackArrow className={classes.backArrowIcon} />}
          >
            Back
          </Button>
          <Button
            className={clsx(classes.calendarButton, classes.confirmDateButton)}
            variant={"contained"}
            disabled={hasNoChanges}
            onClick={handleClickConfirmDateButton}
          >
            Confirm Date
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          className={classes.actionButton}
          onClick={onCloseModal}
          data-test-id={"submitExamCancelButton"}
        >
          Cancel
        </Button>
        <Button
          className={clsx(classes.actionButton, classes.confirmButton)}
          onClick={handleClickSuccessButton}
          data-test-id={"submitExamConfirmSubmitButton"}
        >
          Confirm
        </Button>
      </>
    );
  };

  return (
    <Dialog className={classes.modal} open={true} onClose={onCloseModal}>
      <DialogTitle
        className={clsx(classes.dialogTitle, { calendarOpen: isCalendarOpen })}
        disableTypography={true}
      >
        {dialogTitle}
      </DialogTitle>

      <DialogContent
        className={clsx(classes.dialogContent, {
          calendarOpen: isCalendarOpen,
          sixWeekMonth: isSixWeekMonth
        })}
      >
        {renderContent()}
      </DialogContent>

      <DialogActions
        className={clsx(classes.dialogActions, {
          calendarOpen: isCalendarOpen
        })}
      >
        {renderActionsButtons()}
      </DialogActions>
    </Dialog>
  );
};

export default SubmitExamModal;
