import React, { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";

import RejectionNote from "pages/WorkoutsPage/Vet/WorkoutsTable/WorkoutRow/RejectionNote";
import AppPageTableRow from "components/AppPageTableRow";
import AppPageTableRowHeader from "components/AppPageTableRowHeader";
import AppPageTableRowContent from "components/AppPageTableRowContent";
import HorseLink from "./RowHeader/HorseLink";
import FlagPriorityIndicator from "./RowHeader/FlagPriorityIndicator";
import FlagOnTrack from "./RowHeader/FlagOnTrack/FlagOnTrack";
import LastExamNote from "../LastExamNote";
import ExamsTablePPs from "../ExamsTablePPs";
import { IExamsTableRowData } from "../types";

import useStyles from "./styles";

interface IProps {
  index: number;
  rowData: IExamsTableRowData;
  examNoteContent?: ReactElement;
  actionCellsContent?: ReactElement;
  rowContainerClassName?: string;
  rowContentClassName?: string;
  shouldShowFlagPriorityIndicator?: boolean;
  shouldShowFlagOnTrack?: boolean;
  shouldShowLastExamNote?: boolean;
  shouldShowExamsTablePPs?: boolean;
  shouldShowRejectionNote?: boolean;
}

const Row = (props: PropsWithChildren<IProps>) => {
  const {
    children,
    index,
    rowData,
    examNoteContent,
    actionCellsContent,
    rowContentClassName,
    rowContainerClassName,
    shouldShowFlagPriorityIndicator = false,
    shouldShowFlagOnTrack = false,
    shouldShowLastExamNote = false,
    shouldShowExamsTablePPs = false,
    shouldShowRejectionNote = false
  } = props;
  const { horse, eventFlag, eventFlagPriority, eventRejectionNote } = rowData;

  const classes = useStyles();

  return (
    <AppPageTableRow
      className={clsx(rowContainerClassName, {
        [classes.rowOdd]: index % 2
      })}
      data-test-id={"tableRow"}
    >
      <AppPageTableRowHeader>
        <HorseLink horseId={horse?.id}>{horse?.name}</HorseLink>
        {shouldShowFlagPriorityIndicator && (
          <FlagPriorityIndicator
            priority={eventFlag?.priority || eventFlagPriority}
          />
        )}
        {shouldShowFlagOnTrack && (
          <FlagOnTrack
            flagOnTrackCommentId={horse?.flagOnTrack?.flagOnTrackComment?.id}
          />
        )}
      </AppPageTableRowHeader>
      <AppPageTableRowContent>
        <div>
          <div className={classes.rowWrapper}>
            <div className={clsx(classes.rowContent, rowContentClassName)}>
              {children}
            </div>
          </div>
          {shouldShowLastExamNote && <LastExamNote horse={horse} />}
          {examNoteContent}
          {shouldShowExamsTablePPs && <ExamsTablePPs horse={horse} />}
          {shouldShowRejectionNote && (
            <RejectionNote note={eventRejectionNote} />
          )}
        </div>
      </AppPageTableRowContent>

      {actionCellsContent}
    </AppPageTableRow>
  );
};

export default React.memo(Row);
