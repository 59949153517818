import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { useRacehorse360Api } from "hooks/api";
import { filterTableRow } from "store/actions/preRaceScreeningPage";
import ActionCellCheckbox from "components/VetExamsTable/TableRow/RowContent/ActionCellsWrapper/ActionCellCheckbox";
import ActionCellsWrapper from "components/VetExamsTable/TableRow/RowContent/ActionCellsWrapper";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";

import useParentStyles from "../styles";
import useStyles from "./styles";

interface IProps {
  entryId: string;
  index: number;
  isExamChecked: boolean;
  isPassChecked: boolean;
  onSetIsExamChecked: (value: boolean) => void;
  onSetIsPassChecked: (value: boolean) => void;
}

const PreRaceRowActionCells: React.FC<
  IProps & WithClickBlockerProps
> = props => {
  const {
    entryId,
    index,
    isPassChecked,
    isExamChecked,
    onSetIsExamChecked,
    onSetIsPassChecked,
    handleBlockableClick
  } = props;

  const classes = useStyles();
  const parentClasses = useParentStyles();
  const dispatch = useDispatch();

  const { useRequireUpcomingRaceEntryExam, useQuickPassUpcomingRaceEntry } =
    useRacehorse360Api();

  const {
    mutateAsync: createUpcomingRaceEntryExam,
    isPending: isUpcomingRaceEntryExamCreating
  } = useRequireUpcomingRaceEntryExam();

  const {
    mutateAsync: quickPassUpcomingRaceEntry,
    isPending: isQuickPassUpcomingRaceEntryLoading
  } = useQuickPassUpcomingRaceEntry();

  const handleExamCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleBlockableClick(() => {
      const checked = event.target.checked;

      onSetIsExamChecked(checked);
      checked && onSetIsPassChecked(false);

      createUpcomingRaceEntryExam({ id: entryId }).then(raceEntry => {
        dispatch(filterTableRow(raceEntry.id));
      });
    });
  };

  const handlePassCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleBlockableClick(() => {
      const checked = event.target.checked;

      onSetIsPassChecked(checked);
      checked && onSetIsExamChecked(false);

      quickPassUpcomingRaceEntry({ id: entryId }).then(raceEntry => {
        dispatch(filterTableRow(raceEntry.id));
      });
    });
  };

  return (
    <ActionCellsWrapper
      className={clsx({
        [parentClasses.rowOdd]: index % 2,
        [parentClasses.rowExamChecked]: isExamChecked,
        [parentClasses.rowPassChecked]: isPassChecked
      })}
    >
      <ActionCellCheckbox
        className={clsx({ [classes.examCheckboxChecked]: isExamChecked })}
        checked={isExamChecked}
        isLoading={isUpcomingRaceEntryExamCreating}
        onChange={handleExamCheckChange}
        dataTestId={"examCheckbox"}
      />
      <ActionCellCheckbox
        className={clsx({ [classes.passCheckboxChecked]: isPassChecked })}
        checked={isPassChecked}
        isLoading={isQuickPassUpcomingRaceEntryLoading}
        onChange={handlePassCheckChange}
        dataTestId={"quickPassCheckbox"}
      />
    </ActionCellsWrapper>
  );
};

export default withClickBlocker<IProps>(React.memo(PreRaceRowActionCells));
