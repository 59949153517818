import React, { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory } from "react-router-dom";
import clsx from "clsx";

import Divider from "@material-ui/core/Divider";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import Conditions from "components/Conditions";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { usePermissions } from "components/PermissionsProvider";
import PreRaceScreeningPageIcon from "components/Icons/PreRaceScreeningPageIcon";
import HorseIcon from "components/Icons/Horse";
import CalendarIcon from "components/Icons/Calendar";
import HorseShoeIcon from "components/Icons/HorseShoe";
import WrenchIcon from "components/Icons/Wrench";
import PreferredConditionsIcon from "components/Icons/PreferredConditions";
import TrackOpticsIcon from "components/Icons/TrackOpticsPageIcon";
import InventoryAnalyticsIcon from "components/Icons/InventoryAnalyticsIcon";
import routes from "common/routes";
import { IRoute, matchRoute } from "utils/routes";
import {
  setActiveLink as setStoreActiveLink,
  setStoreActiveLinkCandidate as setStoreClickedLink
} from "store/actions/sidebar";
import SideBarButton from "./SideBarButton";
import LogoIconButton from "./LogoIconButton";

import useStyles from "./styles";

interface ISideBarButton {
  title: string;
  route: IRoute;
  icon: React.ComponentType<{ className?: string }>;
  isDisplayed: boolean;
  linkState?: Record<string, any>;
  dataTestId?: string;
}

export interface IProps {
  isSwipeableDrawer?: boolean;
  className?: string;
  onLinkClick?: () => void;
}

const shouldHideShadow = path => {
  const shadowlessRoutes = [
    routes.stallApplication.path,
    routes.stallApplicationNewForm.path
  ];

  return shadowlessRoutes.some(route =>
    matchPath(path, {
      path: route,
      exact: true,
      strict: true
    })
  );
};

const AppSideBar: React.FC<IProps & WithClickBlockerProps> = props => {
  const {
    className,
    onLinkClick,
    handleBlockableLinkClick,
    isSwipeableDrawer
  } = props;

  const classes = useStyles();
  const {
    isStallApplicationPageEnabled,
    isWorkOrdersPageEnabled,
    isInventoryAnalyticsPageEnabled
  } = usePermissions();
  const { currentUser } = useLoggedInUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeLink = useSelector(
    (state: { sidebar }) => state?.sidebar.activeLink
  );

  const handleMenuClick = (
    event: SyntheticEvent,
    route: string,
    state?: Record<string, any>
  ) => {
    const isBlockedClick = handleBlockableLinkClick
      ? handleBlockableLinkClick(event)
      : false;

    if (isBlockedClick) {
      dispatch(setStoreClickedLink(route));
    } else {
      dispatch(setStoreActiveLink(route));
      history.push(route, state);
      onLinkClick && onLinkClick();
    }
  };

  const isApprovedUser: boolean = currentUser.roles.length > 0;
  const shouldCalendarBeHidden: boolean = true; // this should be hidden for all users for now
  const shouldShowDashboard: boolean = currentUser.isTrainer;
  const shouldShowStallApplication: boolean =
    (currentUser.isTrainer || currentUser.isRacingOfficial) &&
    isStallApplicationPageEnabled;
  const shouldShowWorkoutRequests: boolean =
    !currentUser.isMaintenance && !currentUser.isRegulatoryVet;
  const shouldShowPreferredList: boolean =
    currentUser.isRacingOfficial || currentUser.isAdmin;
  const shouldShowConditionBook: boolean = !currentUser.isRegulatoryVet;
  const shouldShowWorkOrders: boolean =
    !currentUser.isRegulatoryVet && isWorkOrdersPageEnabled;
  const shouldShowAdmin: boolean = currentUser.isAdmin;
  const shouldShowHorseDetails: boolean = !currentUser.isMaintenance;
  const shouldShowInventoryAnalytics: boolean =
    currentUser.isRacingOfficial && isInventoryAnalyticsPageEnabled;
  const shouldShowTrackOptics: boolean =
    currentUser.isVeterinarian && !currentUser.isRegulatoryVet;
  const shouldShowPreRaceScreening: boolean =
    currentUser.isVeterinarian && !currentUser.isRegulatoryVet;
  //TODO: fix PreferredConditions page for basic roles and uncomment lines below
  // ||
  // currentUser.isAdmin ||
  // currentUser.isMaintenance;

  const sideBarButtonsData: ISideBarButton[] = [
    {
      title: "Dashboard",
      route: routes.dashboard,
      icon: DashboardIcon,
      isDisplayed: shouldShowDashboard,
      dataTestId: "dashboardButton"
    },
    {
      title: "Workouts",
      route: routes.workouts,
      icon: HorseIcon,
      isDisplayed: shouldShowWorkoutRequests,
      dataTestId: "workoutsButton"
    },
    {
      title: "Calendar",
      route: routes.calendar,
      icon: CalendarIcon,
      isDisplayed: !shouldCalendarBeHidden,
      dataTestId: "calendarButton"
    },
    {
      title: "Horse Details",
      route: routes.horseList,
      icon: HorseShoeIcon,
      isDisplayed: shouldShowHorseDetails,
      dataTestId: "horseDetailsButton"
    },
    {
      title: "Work Orders",
      route: routes.workOrders,
      icon: WrenchIcon,
      isDisplayed: shouldShowWorkOrders,
      dataTestId: "workOrdersButton"
    },
    {
      title: "Conditions List",
      route: routes.preferredConditions,
      icon: PreferredConditionsIcon,
      isDisplayed: shouldShowPreferredList,
      dataTestId: "conditionsListButton"
    },
    {
      title: "Condition Book",
      route: routes.conditionBook,
      icon: FormatListNumberedIcon,
      isDisplayed: shouldShowConditionBook,
      dataTestId: "conditionBookButton"
    },
    {
      title: "Stall Application",
      route: routes.stallApplication,
      icon: PlaylistAdd,
      isDisplayed: shouldShowStallApplication,
      dataTestId: "stallApplicationButton"
    },
    {
      title: "TrackOptics",
      route: routes.trackOptics,
      icon: TrackOpticsIcon,
      isDisplayed: shouldShowTrackOptics,
      dataTestId: "trackOpticsButton"
    },
    {
      title: "Inventory Analytics",
      route: routes.inventoryAnalytics,
      icon: InventoryAnalyticsIcon,
      isDisplayed: shouldShowInventoryAnalytics,
      dataTestId: "inventoryAnalyticsButton"
    },
    {
      title: "Race Entries",
      route: routes.preRaceScreening,
      icon: PreRaceScreeningPageIcon,
      isDisplayed: shouldShowPreRaceScreening,
      dataTestId: "preRaceScreeningButton"
    },
    {
      title: "Admin",
      route: routes.admin,
      icon: SupervisorAccountIcon,
      isDisplayed: shouldShowAdmin,
      dataTestId: "adminButton"
    }
  ];

  const sideBarButtons = sideBarButtonsData.map((item, index) => {
    const { title, icon, route, dataTestId, isDisplayed, linkState } = item;

    return (
      isDisplayed && (
        <SideBarButton
          key={index + route.path}
          onClick={handleMenuClick}
          route={route}
          Icon={icon}
          linkState={linkState}
          dataTestId={dataTestId}
        >
          {title}
        </SideBarButton>
      )
    );
  });

  return (
    isApprovedUser && (
      <section
        className={clsx(classes.appSideBarRoot, className, {
          [classes.appSideBarRootShadowless]: shouldHideShadow(
            history.location.pathname
          )
        })}
      >
        <div className={classes.appSideBarContent}>
          <nav
            className={clsx(classes.appSideBarLinks, {
              swipeableDrawerOpen: isSwipeableDrawer
            })}
            role="navigation"
            aria-label="main mailbox folders"
          >
            <LogoIconButton onClickLogo={handleMenuClick} />

            {sideBarButtons}
          </nav>
          <div>
            <Divider />
            <Conditions
              selected={matchRoute(activeLink, [routes.manageTracks], false)}
              openManageTracksPage={handleMenuClick}
              dataPath={routes.manageTracks.path}
            />
          </div>
        </div>
      </section>
    )
  );
};

export default withClickBlocker<IProps>(AppSideBar);
