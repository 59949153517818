import addDays from "date-fns/addDays";
import differenceInHours from "date-fns/differenceInHours";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import { differenceInDays, isFuture } from "date-fns";
import addHours from "date-fns/addHours";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import formatISO from "date-fns/formatISO";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import isValid from "date-fns/isValid";
import startOfDay from "date-fns/startOfDay";
import parseISO from "date-fns/parseISO";
import { racehorse360 } from "@tsg/1st-grpc-web";

type WorkoutRequestOrPlaceHolder =
  | { date: string }
  | racehorse360.IWorkoutRequest;

export interface IDaysAndHours {
  days: number;
  hours: number;
}

export const DAY_IN_HOURS = 24;
export const DAY_IN_MINUTES = 1440;
export const HOUR_IN_MINUTES = 60;

// Returns a list of string dates
// like: [ "2020-01-01", "2020-01-02", ...]
export const getNextDates = (
  currentDate: Date,
  totalCells: number = 42
): string[] => {
  const today = currentDate;
  const monthDays = [];

  for (let i = 0; i < totalCells; i++) {
    monthDays.push(formatISO(addDays(today, i), { representation: "date" }));
  }

  return monthDays;
};

export function getTypedDateRange<TItem>(
  startDate: Date,
  totalCells: number = 42,
  decorator: (date: Date) => TItem
): TItem[] {
  const dates = [];

  for (let i = 0; i < totalCells; i++) {
    dates.push(decorator(addDays(startDate, i)));
  }

  return dates;
}

interface Calendar {
  [monthKey: string]: {
    [dateKey: number]: { date: string } | racehorse360.IWorkoutRequest;
  };
}

// Returns an object of months and dates
export const getCalendar = (
  startDate: Date,
  totalCells: number = 30
): Calendar => {
  const months: Calendar = {};
  for (let i = 0; i < totalCells; i++) {
    const date = addDays(startDate, i);
    const monthKey = format(date, "yyyy-MM");
    if (!months[monthKey]) {
      months[monthKey] = {};
    }
    if (months[monthKey]) {
      const dateKey = date.getDate();
      months[monthKey][dateKey] = {
        date: formatISO(date, { representation: "date" })
      };
    }
  }

  return months;
};

//TODO: simplify this object
export const mergeDates = (
  futureDays: WorkoutRequestOrPlaceHolder[],
  scheduleDays: racehorse360.IWorkoutRequest[]
): WorkoutRequestOrPlaceHolder[] => {
  return futureDays.map(futureDay => {
    const scheduledDay = scheduleDays.find(scheduleDay =>
      isSameDay(parseISO(scheduleDay.date), parseISO(futureDay.date))
    );

    return scheduledDay || futureDay;
  });
};

export const isBetweenDates = (date: Date, start: Date, end: Date): boolean => {
  return isAfterDate(date, start) && isBeforeDate(date, end);
};

export const isBeforeDate = (date: Date, dateToCompare: Date): boolean => {
  const startOfDateToCompare = startOfDay(dateToCompare);
  return isBefore(date, startOfDateToCompare);
};

export const isAfterDate = (date: Date, dateToCompare: Date): boolean => {
  const startOfDateToCompare = endOfDay(dateToCompare);
  return isAfter(date, startOfDateToCompare);
};

export const currentTimeZoneString = (): string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    console.log(
      "Current browser doesn't support Intl.DateTimeFormat().ResolvedOptions().TimeZone",
      error
    );
  }
};

export const shouldShutOffDate = (
  calendarDate: Date,
  facility: racehorse360.IFacility
): boolean => {
  const { shutOffPeriodInHours, shutOffHours, timezone: timeZone } = facility;

  if (!shutOffPeriodInHours) return false;

  const currentFacilityTime = new Date(
    new Date().toLocaleString("en-US", { timeZone })
  );

  const deadlineFacilityTime = new Date(
    new Date(currentFacilityTime).setHours(Number(shutOffHours), 0, 0)
  );

  const referencePointDate =
    currentFacilityTime > deadlineFacilityTime
      ? addDays(currentFacilityTime.setHours(0, 0, 0), 1)
      : currentFacilityTime.setHours(0, 0, 0);

  const deadlineDate = new Date(
    addHours(referencePointDate, shutOffPeriodInHours)
  );

  const calendarFacilityDate = new Date(
    calendarDate.toLocaleString("en-US", { timeZone })
  );

  const deadlineFacilityDate = startOfDay(
    new Date(deadlineDate.toLocaleString("en-US", { timeZone }))
  );

  return isBeforeDate(calendarFacilityDate, deadlineFacilityDate);
};

export const getDaysHoursFromCreatedOn = (
  dateFrom: string
): IDaysAndHours | null => {
  let days: number, hours: number;

  if (dateFrom) {
    const isoDateFrom = parseISO(dateFrom);
    const intervalInHours = differenceInHours(
      new Date(),
      new Date(isoDateFrom)
    );
    days = Math.floor(intervalInHours / DAY_IN_HOURS);
    hours = Math.floor(intervalInHours - days * DAY_IN_HOURS);

    return { days, hours };
  }

  return null;
};

export const getTimeAgo = (date: string): string => {
  if (!isValid(parseISO(date))) return "";

  let result =
    formatDistanceToNow(parseISO(date), {
      includeSeconds: true
    }) + " Ago";

  if (
    result.includes("half a minute") ||
    result.includes("less than a minute")
  ) {
    return result;
  }

  [
    { test: "second", value: "s" },
    { test: "minute", value: "min" },
    { test: "hour", value: "h" },
    { test: "day", value: "d" },
    { test: "month", value: "mo" },
    { test: "year", value: "y" }
  ].forEach(word => {
    result = result.replace(
      new RegExp(" " + word.test + "(s)?", "g"),
      word.value
    );
  });

  return result;
};

const getTitleAccordingToQuantity = (title: string, value: number): string => {
  const result = `${value} ${title}`;

  return value === 1 ? result : result + "s";
};

export const convertMinutesToDaysHours = (
  minutes: number,
  isMobile?: boolean
): string => {
  const days = Math.floor(minutes / DAY_IN_MINUTES);
  const hours = Math.round((minutes % DAY_IN_MINUTES) / HOUR_IN_MINUTES);

  if (!days && !hours) {
    const remainingMinutes = (minutes % DAY_IN_MINUTES) % HOUR_IN_MINUTES;

    return isMobile
      ? `${remainingMinutes} min`
      : getTitleAccordingToQuantity("Minute", remainingMinutes);
  }

  return isMobile
    ? `${days}d ${hours}h`
    : `${getTitleAccordingToQuantity(
        "Day",
        days
      )} ${getTitleAccordingToQuantity("Hour", hours)}`;
};

export const transformViewDate = (
  date: string,
  timezone?: string,
  pattern?: string
): string => {
  if (date) {
    const currentPattern = pattern || "M/d/yy";

    return timezone
      ? format(utcToZonedTime(parseISO(date), timezone), currentPattern)
      : format(parseISO(date), currentPattern);
  }
};

export const isFutureDate = (date: string): boolean => {
  return date ? isFuture(parseISO(date)) : false;
};

export const differenceInDaysOrNA = (date?: string): number | string => {
  return date ? differenceInDays(new Date(), parseISO(date)) : "N/A";
};

//Counting difference of days with rounding of fractional days to 1
export const differenceInDaysRoundedUp = (date: Date): number => {
  const differenceInMs = date.getTime() - Date.now();

  return Math.ceil(differenceInMs / (1000 * 3600 * 24)); // translating to days
};

export const getDateInTimeZone = (date: Date, timeZone: string) => {
  return new Date(
    new Date(date.toLocaleString("en-US", { timeZone })).toLocaleDateString(
      "en-US"
    )
  );
};
