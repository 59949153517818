import React from "react";
import clsx from "clsx";

import { getPersonFullName } from "utils/person";
import { transformViewDate } from "utils/date-utils";
import { TExamNote } from "../../types";

import useStyles from "../styles";

interface IProps {
  readOnly: boolean;
  isNoteExists: string;
  onOpen: (e: React.SyntheticEvent) => void;
  examNote: TExamNote;
  noteLabel?: string;
}

const ExamNoteLabel = (props: IProps) => {
  const {
    readOnly,
    isNoteExists,
    onOpen,
    examNote,
    noteLabel = "Exam Reason Note"
  } = props;

  const classes = useStyles();

  return (
    <>
      {readOnly && isNoteExists && (
        <span className={clsx(classes.link, classes.linkReadOnly)}>
          {noteLabel}
        </span>
      )}
      {!readOnly && (
        <a className={classes.link} onClick={onOpen}>
          {isNoteExists ? noteLabel : `Add ${noteLabel}`}
        </a>
      )}
      {isNoteExists && (
        <>
          <span className={classes.date}>
            <span className={classes.dateDash}>{" - "}</span>
            {getPersonFullName(examNote.notedBy, "%f %L")}{" "}
            {transformViewDate(
              examNote.notedOn,
              undefined,
              "MM/dd/yyyy - hh:mm aa"
            )}
          </span>
          <p className={classes.body}>{examNote.body}</p>
        </>
      )}
    </>
  );
};

export default ExamNoteLabel;
