import React, { useContext, useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik, FormikProps } from "formik";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import {
  HORSE_OPTIONS,
  HORSE_EXAM_OPTIONS
} from "pages/HorseDetailsPage/Vet/helper";
import { getLocalStateExam } from "../helper";
import { IVetExamLocalState } from "../options";
import validationSchema from "../validationSchema";

interface VetExamStateContextModel extends FormikProps<IVetExamLocalState> {
  formFieldsItems: racehorse360.IPullHorseExamConditionsResponse;
  currentExam: racehorse360.IHorseExam;
  isDataLoading: boolean;
  shouldValidateOnChange: boolean;
  shouldShowSectionValidationResult: boolean;
  setShouldValidateOnChange: (value: boolean) => void;
  setShouldShowSectionValidationResult: (value: boolean) => void;
}

export interface IProps {
  children: React.ReactNode;
  examId: string;
}

const GET_WORKOUT_EXAM_REQUEST_KEY = "getCurrentWorkoutExamKey";

export const VetExamStateContext =
  React.createContext<Partial<VetExamStateContextModel> | null>(null);

export const useVetExamContext = () => useContext(VetExamStateContext);

const VetExamProvider = (props: IProps) => {
  const { children, examId } = props;

  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
  const [
    shouldShowSectionValidationResult,
    setShouldShowSectionValidationResult
  ] = useState(false);

  const queryClient = useQueryClient();
  const { usePullHorseExamConditions, useGetHorseExam } = useRacehorse360Api();

  const { data: formFieldsItems } = usePullHorseExamConditions({});

  const { data: currentExam } = useGetHorseExam(GET_WORKOUT_EXAM_REQUEST_KEY, {
    id: examId,
    getOptions: {
      select: [
        ...HORSE_EXAM_OPTIONS,
        ...HORSE_OPTIONS.map(item => `horse.${item}`),
        "horseId",
        "type",
        "highSpeedFurlongs",
        "workoutRequestId",
        "workoutRequest.id",
        "workoutRequest.facility.name",
        "workoutRequest.date",
        "workoutRequest.status"
      ]
    }
  });

  const formik = useFormik<IVetExamLocalState>({
    initialValues: null,
    validationSchema,
    onSubmit: null,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: shouldValidateOnChange,
    enableReinitialize: true
  });

  useEffect(() => {
    if (currentExam) {
      formik.setValues(getLocalStateExam(currentExam));
    }
  }, [currentExam]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [GET_WORKOUT_EXAM_REQUEST_KEY]
      });
    };
  }, []);

  return (
    <VetExamStateContext.Provider
      value={{
        formFieldsItems,
        currentExam,
        shouldValidateOnChange,
        setShouldValidateOnChange,
        shouldShowSectionValidationResult,
        setShouldShowSectionValidationResult,
        ...formik
      }}
    >
      {children}
    </VetExamStateContext.Provider>
  );
};

export default React.memo(VetExamProvider);
