import { matchPath } from "react-router-dom";
import ITab from "interfaces/Tab";
import routes from "common/routes";

interface HorseQuery {
  id: string;
  getOptions: {
    select: string[];
  };
}

export const WORKOUT_REQUEST_OPTIONS = [
  "id",
  "facility.name",
  "horse.trainer.firstName",
  "horse.trainer.middleName",
  "horse.trainer.lastName",
  "date",
  "status",
  "created_on",
  "updated_on",
  "facility.id",
  "facility.backgroundColor",
  "facility.foregroundColor",
  "facility.strokeColor"
];
export const HORSE_EXAM_OPTIONS = [
  "id",
  "status",
  "result",
  "reason",
  "note",
  "riskLevel",
  "completedDate",
  "facilityId",
  "facility.id",
  "facility.name",
  "facility.timezone",
  "assignedUserId",
  "assignedUser.id",
  "assignedUser.firstName",
  "assignedUser.lastName",
  "commentToTrainer.id",
  "commentToTrainer.body",
  "updatedById",
  "updatedOn",
  "workoutRequest",
  "upcomingRaceEntry",
  "jogs:jogConditionId",
  "jogs:jogCondition",
  "jogs:customJogCondition",
  "jogs:jogScoreId",
  "jogs:jogScore",
  "jogs:jogInfoId",
  "jogs:jogInfo",
  "jogs:customJogInfo",
  "jogs:jogLimbId",
  "jogs:jogLimb",
  "jogs:customJogLimb",
  "jogs:createdOn",
  "examDetails:detailLasixCCsId",
  "examDetails:detailLasixCCs",
  "examDetails:detailStructureId",
  "examDetails:detailStructure",
  "examDetails:customDetailStructure",
  "examDetails:detailLimbId",
  "examDetails:detailLimb",
  "examDetails:customDetailLimb",
  "examDetails:detailLocationId",
  "examDetails:detailLocation",
  "examDetails:customDetailLocation",
  "examDetails:observationEntries:notes",
  "examDetails:createdOn",
  "examDetails:observationEntries:detailConditionId",
  "examDetails:observationEntries:detailCondition",
  "examDetails:observationEntries:customDetailCondition",
  "examDetails:observationEntries:detailObservationId",
  "examDetails:observationEntries:detailObservation",
  "examDetails:observationEntries:customDetailObservation",
  "examDetails:observationEntries:detailSeverityId",
  "examDetails:observationEntries:detailSeverity"
];
export const HORSE_OPTIONS = [
  "birthday",
  "currentFacility.name",
  "currentFacility.code",
  "currentFacility.workout_required_days_off",
  "currentFacility.backgroundColor",
  "currentFacility.foregroundColor",
  "currentFacility.strokeColor",
  "barn.name",
  "barn.facility.name",
  "barn.facility.code",
  "barn.facility.foreground_color",
  "stall.name",
  "dam_name",
  "last_race_date",
  "last_race_facility.code",
  "last_race_facility.name",
  "last_workout_date",
  "last_workout_facility.code",
  "last_workout_facility.name",
  "name",
  "breeder.name",
  "owner.first_name",
  "owner.last_name",
  "registration_number",
  "sire_name",
  "trainer.first_name",
  "trainer.middle_name",
  "trainer.last_name",
  "color_description",
  "healthWatchStatus",
  "gender",
  "chip_number",
  "color",
  "dateOfDeath",
  "riskFactors",
  "riskFactorsCount",
  "flagOnTrack",
  "flagOnTrack.flagOnTrackComment.body",
  "flagOnTrack.flagOnTrackComment.updatedOn",
  "flagOnTrack.flagOnTrackComment.updatedBy",
  "flagOnTrack.flagOnTrackComment.updatedBy.firstName",
  "flagOnTrack.flagOnTrackComment.updatedBy.lastName",
  "lifetime"
];

export enum TabValues {
  ACTIVITY = "activity",
  DETAILS = "details",
  COMMENTS = "comments"
}

export const tabsList: ITab[] = [
  {
    name: "Activity",
    value: TabValues.ACTIVITY,
    path: routes.horseDetailsActivity.path
  },
  {
    name: "Details",
    value: TabValues.DETAILS,
    path: routes.horseDetails.path
  },
  {
    name: "Comments",
    value: TabValues.COMMENTS,
    path: routes.horseDetailsComments.path
  }
];

export const getHorseQuery = (id: string): HorseQuery => ({
  id,
  getOptions: {
    select: HORSE_OPTIONS
  }
});

export const getTabFromPath = (pathname: string): ITab => {
  return (
    tabsList.find(t =>
      matchPath(pathname, {
        path: t.path,
        exact: true,
        strict: true
      })
    ) || tabsList[1]
  );
};
