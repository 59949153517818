import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  examCheckboxChecked: {
    backgroundColor: "rgba(240, 227, 191, 1.0)",
    "&:hover": {
      backgroundColor: "rgba(240, 227, 191, 1.0)"
    }
  },
  passCheckboxChecked: {
    backgroundColor: "rgba(3, 243, 178, 1.0)",
    "&:hover": {
      backgroundColor: "rgba(3, 243, 178, 1.0)"
    }
  }
});
