import React, { SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core";

import ContentMobile from "./ContentMobile";
import Breakpoints from "common/breakpoints";
import ContentDesktop from "./ContentDesktop";
import {
  setActiveLink,
  setStoreActiveLinkCandidate
} from "store/actions/sidebar";
import ExamNoteLabel from "./ExamNoteLabel";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import { IExamsTableRowData } from "components/VetExamsTable/types";

import useStyles from "./styles";

interface IProps {
  readOnly: boolean;
  index: number;
  isLoading: boolean;
  onSaveComment: (note: string) => Promise<void>;
  onDeleteComment: () => Promise<void>;
  rowData: IExamsTableRowData;
  additionalDesktopClassNames?: string;
  noteLabel?: string;
}

const DEFAULT_NOTE_LABEL = "Exam Reason Note";

const ExamNote: React.FC<IProps & WithClickBlockerProps> = props => {
  const {
    index,
    readOnly,
    handleBlockableClick,
    isLoading,
    onSaveComment,
    onDeleteComment,
    rowData: { examNote, horse },
    additionalDesktopClassNames,
    noteLabel = DEFAULT_NOTE_LABEL
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(examNote?.body || "");

  const clickedLink = useSelector(
    (state: { sidebar }) => state?.sidebar.activeLinkCandidate
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const isNoteExists = examNote?.id;

  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(Breakpoints.SX_720)
  );

  const redirectToLinkCandidate = () => {
    if (clickedLink) {
      dispatch(setActiveLink(clickedLink));
      dispatch(setStoreActiveLinkCandidate(""));
      history.push(clickedLink);
    }
  };

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    handleBlockableClick(() => setOpen(true));
  };

  const handleClose = () => {
    setOpen(false);
    setNote("");
    redirectToLinkCandidate();
  };

  const handleNoteChange = value => {
    setNote(value);
  };

  const handleSave = async () => {
    await onSaveComment(note);
    handleClose();
  };

  const handleDelete = async () => {
    await onDeleteComment();
    handleClose();
  };

  return (
    <div
      className={clsx(classes.examNote, {
        [classes.examNoteDesktop]: isDesktop && open
      })}
    >
      {!open && (
        <ExamNoteLabel
          isNoteExists={isNoteExists}
          readOnly={readOnly}
          onOpen={handleOpen}
          examNote={examNote}
          noteLabel={noteLabel}
        />
      )}
      {!readOnly &&
        (isDesktop ? (
          <ContentDesktop
            open={open}
            additionalDesktopClassNames={additionalDesktopClassNames}
            examNote={examNote}
            note={note}
            isLoading={isLoading}
            onClose={handleClose}
            onSave={handleSave}
            onDelete={handleDelete}
            onNoteChange={handleNoteChange}
            isNoteExists={isNoteExists}
            index={index}
            noteLabel={noteLabel}
          />
        ) : (
          <ContentMobile
            open={open}
            examNote={examNote}
            horse={horse}
            note={note}
            isLoading={isLoading}
            onClose={handleClose}
            onSave={handleSave}
            onDelete={handleDelete}
            onNoteChange={handleNoteChange}
            noteLabel={noteLabel}
          />
        ))}
    </div>
  );
};

export default withClickBlocker<IProps>(ExamNote);
