export enum EColumnName {
  HORSE_NAME = "horse.name",
  TRAINER_LASTNAME = "horse.trainer.lastName",
  WORKOUT_REQUEST_DATE = "date",
  WORKOUT_REQUEST_CREATION_DATE = "createdOn",
  BARN_NAME = "horse.barn.name",
  WORKOUT_EXAM_REASON = "workoutExam.reason",
  HEALTH_WATCH_STATUS = "horse.healthWatchStatus",
  WORKOUT_EXAM_TYPE = "workoutExam.type",
  WORKOUT_EXAM_ASSIGNED_USER_LASTNAME = "workoutExam.assignedUser.lastName",
  HORSE_BIRTHDAY = "horse.birthday",
  HIGH_SPEED_FURLONGS = "highSpeedFurlongs",
  LAST_WORKOUT_DATE = "horse.lastWorkoutDate",
  LAST_RACE_DATE = "horse.lastRaceDate",
  LAST_EXAM_DATE = "horse.lastExamDate",
  RISK_FACTORS_COUNT = "horse.riskFactorsCount",
  IS_FLAGGED = "isFlagged",
  ACTIVE_VET_LISTS_COUNT = "horse.activeVetListsCount",
  UN_ASSIGN = "un-assign",
  LAST_COLUMN = "last"
}

interface ColumnSettings {
  columnNames: string[];
  orderBy: string[];
}

export const columnSettings: ColumnSettings[] = [
  {
    columnNames: [EColumnName.HORSE_NAME, EColumnName.TRAINER_LASTNAME],
    orderBy: ["horse.name", "horse.trainer.lastName"]
  },
  {
    columnNames: [EColumnName.WORKOUT_REQUEST_DATE],
    orderBy: ["date"]
  },
  {
    columnNames: [EColumnName.WORKOUT_REQUEST_CREATION_DATE],
    orderBy: ["createdOn"]
  },
  {
    columnNames: [EColumnName.BARN_NAME],
    orderBy: ["horse.barn.name"]
  },
  {
    columnNames: [EColumnName.WORKOUT_EXAM_REASON],
    orderBy: ["workoutExam.reason"]
  },
  {
    columnNames: [EColumnName.HEALTH_WATCH_STATUS],
    orderBy: ["horse.healthWatchStatus"]
  },
  {
    columnNames: [EColumnName.WORKOUT_EXAM_TYPE],
    orderBy: ["workoutExam.type"]
  },
  {
    columnNames: [EColumnName.WORKOUT_EXAM_ASSIGNED_USER_LASTNAME],
    orderBy: ["workoutExam.assignedUser.lastName"]
  },
  {
    columnNames: [EColumnName.HORSE_BIRTHDAY],
    orderBy: ["horse.birthday"]
  },
  {
    columnNames: [EColumnName.HIGH_SPEED_FURLONGS],
    orderBy: ["highSpeedFurlongs"]
  },
  {
    columnNames: [EColumnName.LAST_WORKOUT_DATE],
    orderBy: ["horse.lastWorkoutDate"]
  },
  {
    columnNames: [EColumnName.LAST_RACE_DATE],
    orderBy: ["horse.lastRaceDate"]
  },
  {
    columnNames: [EColumnName.LAST_EXAM_DATE],
    orderBy: ["horse.lastExamDate"]
  },
  {
    columnNames: [EColumnName.RISK_FACTORS_COUNT],
    orderBy: ["horse.riskFactorsCount", "horse.risksGroupTag"]
  },
  {
    columnNames: [EColumnName.IS_FLAGGED],
    orderBy: ["isFlagged"]
  },
  {
    columnNames: [EColumnName.ACTIVE_VET_LISTS_COUNT],
    orderBy: ["horse.activeVetListsCount"]
  },
  {
    columnNames: [EColumnName.UN_ASSIGN],
    orderBy: ["un-assign"]
  },
  {
    columnNames: [EColumnName.LAST_COLUMN],
    orderBy: ["last"]
  }
];

export const commonSelectedFields = [
  "date",
  "createdOn",
  "examReasonNote.body",
  "examReasonNote.notedBy.firstName",
  "examReasonNote.notedBy.lastName",
  "examReasonNote.notedOn",
  "hasComment",
  "highSpeedFurlongs",
  "horse.name",
  "horse.barn.name",
  "horse.birthday",
  "horse.gender",
  "horse.color",
  "horse.healthWatchStatus",
  "horse.lastWorkoutDate",
  "horse.lastRaceDate",
  "horse.lastExamDate",
  "horse.trainer.firstName",
  "horse.trainer.middleName",
  "horse.trainer.lastName"
];
