import { racehorse360 } from "@tsg/1st-grpc-web";
import { EFlagPriority } from "components/VetExamsTable/helper";

export const getWorkoutRequestFlagPriority = (
  priority: EFlagPriority
): racehorse360.WorkoutRequestFlagPriority => {
  switch (priority) {
    case EFlagPriority.LOWEST: {
      return racehorse360.WorkoutRequestFlagPriority
        .WORKOUT_REQUEST_FLAG_PRIORITY_LOWER;
    }
    case EFlagPriority.MEDIUM: {
      return racehorse360.WorkoutRequestFlagPriority
        .WORKOUT_REQUEST_FLAG_PRIORITY_MEDIUM;
    }
    case EFlagPriority.HIGHEST: {
      return racehorse360.WorkoutRequestFlagPriority
        .WORKOUT_REQUEST_FLAG_PRIORITY_HIGHEST;
    }
    default: {
      return racehorse360.WorkoutRequestFlagPriority
        .WORKOUT_REQUEST_FLAG_PRIORITY_INVALID;
    }
  }
};

export const getUpcomingRaceEntryFlagPriority = (
  priority: EFlagPriority
): racehorse360.UpcomingRaceEntryFlagPriority => {
  switch (priority) {
    case EFlagPriority.HIGHEST: {
      return racehorse360.UpcomingRaceEntryFlagPriority
        .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_HIGHEST;
    }
    case EFlagPriority.MEDIUM: {
      return racehorse360.UpcomingRaceEntryFlagPriority
        .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_MEDIUM;
    }
    case EFlagPriority.LOWEST: {
      return racehorse360.UpcomingRaceEntryFlagPriority
        .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_LOWER;
    }
    default: {
      return racehorse360.UpcomingRaceEntryFlagPriority
        .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_INVALID;
    }
  }
};
