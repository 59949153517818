import { checkSortingType } from "utils/sort-utils";
import { SortOrder, SortOrderType } from "interfaces/SortOrder";

export const getSortDirection = (
  columnSorting: string,
  orderBy: string,
  order: SortOrderType,
  isInverted: boolean = false
): SortOrder => {
  if (orderBy !== columnSorting) return;

  const { isOneOfAsc } = checkSortingType(order);

  return isOneOfAsc !== isInverted ? SortOrder.ASC : SortOrder.DESC;
};
