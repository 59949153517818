import { v1 as uuid } from "uuid";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { EMPTY_STRING } from "common/constants";
import { ISelectOption } from "interfaces/ISelectOption";
import { IButtonColors, IButtonGroup } from "../ButtonsGroup/ButtonsGroup";

export interface IExamJog {
  id: string;
  [EVetExamFields.JOG_SCORE]: string;
  [EVetExamFields.JOG_LIMB]: string;
  [ECustomFieldsValues.CUSTOM_JOG_LIMB]: string;
  [EVetExamFields.JOG_INFO]: string;
  [ECustomFieldsValues.CUSTOM_JOG_INFO]: string;
  [EVetExamFields.JOG_CONDITION]: string;
  [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: string;
}

export interface IExamDetail {
  id: string;
  [EVetExamFields.DETAIL_LIMB]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: string;
  [EVetExamFields.DETAIL_STRUCTURE]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: string;
  [EVetExamFields.DETAIL_LOCATION]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: string;
  [EVetExamFields.EXAM_OBSERVATIONS]: IExamDetailObservation[];
}

export interface IExamDetailObservation {
  id?: string;
  [EVetExamFields.DETAIL_OBSERVATION]: string;
  [EVetExamFields.DETAIL_SEVERITY]: string;
  [EVetExamFields.DETAIL_NOTE]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: string;
  [EVetExamFields.DETAIL_CONDITION]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: string;
}

export interface IVetExamLocalState {
  id?: string;
  [EVetExamFields.REASON]: string;
  [EVetExamFields.RESULT]: string;
  [EVetExamFields.RISK_LEVEL]: string;
  [EVetExamFields.INTERNAL_NOTE]: string;
  [EVetExamFields.COMMENT]: string;
  [EVetExamFields.EXAM_JOGS]: IExamJog[];
  [EVetExamFields.EXAM_DETAILS]: IExamDetail[];
}

export interface FormField {
  type: EFieldsTypes;
  key: EVetExamFields;
  title?: EFieldsTitles;
  custom?: string;
  optionsName?: EOptionsGroups;
}

export enum EVetExamFields {
  REASON = "reason",
  JOG_CONDITION = "jogConditionId",
  JOG_SCORE = "jogScoreId",
  JOG_INFO = "jogInfoId",
  JOG_LIMB = "jogLimbId",
  RESULT = "result",
  RISK_LEVEL = "riskLevel",
  INTERNAL_NOTE = "internalNote",
  COMMENT = "commentToTrainer",
  DETAIL_LIMB = "detailLimbId",
  DETAIL_LOCATION = "detailLocationId",
  DETAIL_STRUCTURE = "detailStructureId",
  DETAIL_OBSERVATION = "detailObservationId",
  DETAIL_SEVERITY = "detailSeverityId",
  DETAIL_CONDITION = "detailConditionId",
  DETAIL_NOTE = "notes",
  DETAILS_MEDIA = "media",
  EXAM_DETAILS = "examDetails",
  EXAM_JOGS = "examJogs",
  EXAM_OBSERVATIONS = "observationEntries"
}

export enum ECustomFieldsValues {
  CUSTOM_JOG_LIMB = "customJogLimb",
  CUSTOM_JOG_INFO = "customJogInfo",
  CUSTOM_JOG_CONDITION = "customJogCondition",
  CUSTOM_DETAIL_LIMB = "customDetailLimb",
  CUSTOM_DETAIL_LOCATION = "customDetailLocation",
  CUSTOM_DETAIL_STRUCTURE = "customDetailStructure",
  CUSTOM_DETAIL_OBSERVATION = "customDetailObservation",
  CUSTOM_DETAIL_CONDITION = "customDetailCondition"
}

export enum EFieldsTypes {
  SELECT = "select",
  BUTTONS_GROUP = "buttonsGroup",
  TEXT = "text",
  MEDIA = "media"
}

export enum EFieldsTitles {
  LIMB = "Limb",
  JOG_SCORE = "Jog Score",
  JOG_INFO = "Jog Information",
  JOG_CONDITION = "Jog Condition",
  LOCATION = "Location",
  STRUCT = "Struct",
  OBSERVATION = "Observation",
  SEVERITY = "Severity",
  CONDITION = "Condition",
  CONDITION_NOTE = "Condition Note"
}

export enum EOptionsGroups {
  LIMBS = "limbs",
  DETAIL_LOCATIONS = "detailLocations",
  DETAILS_STRUCTURES = "detailStructures",
  DETAIL_OBSERVATIONS = "detailObservations",
  DETAILS_CONDITIONS = "detailConditions",
  DETAILS_SEVERITY = "detailSeverities",
  JOG_SCORES = "jogScores",
  JOG_INFOS = "jogInfos",
  JOG_CONDITIONS = "jogConditions"
}

export const FRONT_ID_PREFIX = "frontId-";
export const MAX_ENTRIES_AMOUNT = 25;

export const generateDummyId = () => `${FRONT_ID_PREFIX}${uuid()}`;

export const createEmptyExamDetailObservation = (
  id?: string
): IExamDetailObservation => ({
  id: id || generateDummyId(),
  [EVetExamFields.DETAIL_SEVERITY]: EMPTY_STRING,
  [EVetExamFields.DETAIL_NOTE]: EMPTY_STRING,
  [EVetExamFields.DETAIL_CONDITION]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: EMPTY_STRING,
  [EVetExamFields.DETAIL_OBSERVATION]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: EMPTY_STRING
});

export const createEmptyExamJog = (id?: string): IExamJog => ({
  id: id || generateDummyId(),
  [EVetExamFields.JOG_SCORE]: EMPTY_STRING,
  [EVetExamFields.JOG_LIMB]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_JOG_LIMB]: EMPTY_STRING,
  [EVetExamFields.JOG_INFO]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_JOG_INFO]: EMPTY_STRING,
  [EVetExamFields.JOG_CONDITION]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: EMPTY_STRING
});

export const createEmptyExamDetail = (id?: string): IExamDetail => ({
  id: id || generateDummyId(),
  [EVetExamFields.DETAIL_LIMB]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: EMPTY_STRING,
  [EVetExamFields.DETAIL_STRUCTURE]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: EMPTY_STRING,
  [EVetExamFields.DETAIL_LOCATION]: EMPTY_STRING,
  [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: EMPTY_STRING,
  [EVetExamFields.EXAM_OBSERVATIONS]: [createEmptyExamDetailObservation()]
});

export const createVetExamInitialState = (
  exam: racehorse360.IHorseExam
): IVetExamLocalState => ({
  id: exam.id,
  [EVetExamFields.REASON]: EMPTY_STRING,
  [EVetExamFields.RESULT]: EMPTY_STRING,
  [EVetExamFields.RISK_LEVEL]: EMPTY_STRING,
  [EVetExamFields.INTERNAL_NOTE]: EMPTY_STRING,
  [EVetExamFields.COMMENT]: EMPTY_STRING,
  [EVetExamFields.EXAM_JOGS]: [createEmptyExamJog(exam.jogs?.[0]?.id)],
  [EVetExamFields.EXAM_DETAILS]: [
    {
      ...createEmptyExamDetail(exam.examDetails?.[0]?.id),
      [EVetExamFields.EXAM_OBSERVATIONS]: [
        {
          ...createEmptyExamDetailObservation(
            exam.examDetails?.[0]?.observationEntries[0]?.id
          )
        }
      ]
    }
  ]
});

// Exam Reasons block info
export const examReasons: ISelectOption[] = [
  {
    name: "Workout Request",
    value: String(
      racehorse360.HorseExamReason.HORSE_EXAM_REASON_WORKOUT_REQUEST
    )
  },
  {
    name: "Flagged Workout",
    value: String(
      racehorse360.HorseExamReason.HORSE_EXAM_REASON_FLAGGED_WORKOUT
    )
  },
  {
    name: "Trainer Requested",
    value: String(
      racehorse360.HorseExamReason.HORSE_EXAM_REASON_TRAINER_REQUESTED
    )
  },
  {
    name: "Panel Exam",
    value: String(racehorse360.HorseExamReason.HORSE_EXAM_REASON_PANEL_EXAM)
  },
  {
    name: "Regulatory Vet",
    value: String(racehorse360.HorseExamReason.HORSE_EXAM_REASON_REGULATORY_VET)
  },
  {
    name: "Pre-Race",
    value: String(racehorse360.HorseExamReason.HORSE_EXAM_REASON_PRE_RACE)
  },
  {
    name: "Pre-Work",
    value: String(racehorse360.HorseExamReason.HORSE_EXAM_REASON_PRE_WORK)
  },
  {
    name: "Entry Exam",
    value: String(racehorse360.HorseExamReason.HORSE_EXAM_REASON_ENTRY_EXAM)
  },
  {
    name: "Race Day Exam",
    value: String(racehorse360.HorseExamReason.HORSE_EXAM_REASON_RACE_DAY_EXAM)
  }
];

export const buttonGroupColors: IButtonColors[] = [
  { activeColor: "#489B77" },
  { activeColor: "#4FA352" },
  { activeColor: "#82BE52" },
  { activeColor: "#BEBA49" },
  { activeColor: "#CD8848" },
  { activeColor: "#FF4A4E" }
];

// Exam Results block info
export const examResultsButtons: IButtonGroup[] = [
  {
    id: String(racehorse360.HorseExamResult.HORSE_EXAM_RESULT_PASSED),
    value: "Pass",
    activeColor: "#489B77",
    description: null,
    dataTestLabel: "examPassedButton"
  },
  {
    id: String(racehorse360.HorseExamResult.HORSE_EXAM_RESULT_FAILED),
    value: "Fail",
    activeColor: "#FF4B4F",
    description: null,
    dataTestLabel: "examFailedButton"
  }
];

export const jogInfoFields: FormField[] = [
  {
    title: EFieldsTitles.LIMB,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.JOG_LIMB,
    custom: ECustomFieldsValues.CUSTOM_JOG_LIMB,
    optionsName: EOptionsGroups.LIMBS
  },
  {
    title: EFieldsTitles.JOG_SCORE,
    type: EFieldsTypes.BUTTONS_GROUP,
    key: EVetExamFields.JOG_SCORE,
    optionsName: EOptionsGroups.JOG_SCORES
  },
  {
    title: EFieldsTitles.JOG_INFO,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.JOG_INFO,
    custom: ECustomFieldsValues.CUSTOM_JOG_INFO,
    optionsName: EOptionsGroups.JOG_INFOS
  },
  {
    title: EFieldsTitles.JOG_CONDITION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.JOG_CONDITION,
    custom: ECustomFieldsValues.CUSTOM_JOG_CONDITION,
    optionsName: EOptionsGroups.JOG_CONDITIONS
  },
  {
    type: EFieldsTypes.MEDIA,
    key: EVetExamFields.DETAILS_MEDIA
  }
];

export const examDetailsFields: FormField[] = [
  {
    title: EFieldsTitles.LIMB,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_LIMB,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_LIMB,
    optionsName: EOptionsGroups.LIMBS
  },
  {
    title: EFieldsTitles.LOCATION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_LOCATION,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_LOCATION,
    optionsName: EOptionsGroups.DETAIL_LOCATIONS
  },
  {
    title: EFieldsTitles.STRUCT,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_STRUCTURE,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE,
    optionsName: EOptionsGroups.DETAILS_STRUCTURES
  }
];

export const examDetailsObservationsFields: FormField[] = [
  {
    title: EFieldsTitles.OBSERVATION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_OBSERVATION,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION,
    optionsName: EOptionsGroups.DETAIL_OBSERVATIONS
  },
  {
    title: EFieldsTitles.SEVERITY,
    type: EFieldsTypes.BUTTONS_GROUP,
    key: EVetExamFields.DETAIL_SEVERITY,
    optionsName: EOptionsGroups.DETAILS_SEVERITY
  },
  {
    title: EFieldsTitles.CONDITION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_CONDITION,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_CONDITION,
    optionsName: EOptionsGroups.DETAILS_CONDITIONS
  },
  {
    title: EFieldsTitles.CONDITION_NOTE,
    type: EFieldsTypes.TEXT,
    key: EVetExamFields.DETAIL_NOTE
  },
  {
    type: EFieldsTypes.MEDIA,
    key: EVetExamFields.DETAILS_MEDIA
  }
];
