import React, { useState } from "react";
import format from "date-fns/format";
import clsx from "clsx";

import { Button, DialogActions } from "@material-ui/core";

import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Textarea from "components/Textarea";
import { useClickBlockerContext } from "components/BlockableClickContext";
import { TExamNote } from "../../types";

import useStyles from "../styles";

interface IProps {
  open: boolean;
  isLoading: boolean;
  examNote: TExamNote;
  additionalDesktopClassNames: string;
  note: string;
  onClose: () => void;
  onSave: () => void;
  onDelete: (event: React.MouseEvent) => void;
  onNoteChange: (
    event: React.ChangeEvent<{
      value: string;
    }>
  ) => void;
  isNoteExists: string;
  index: number;
  noteLabel: string;
}

const UNSAVED_CHANGES_TEXT =
  "You have unsaved changes to your exam reason note. Would you like to keep or discard these changes?";

const ContentDesktop = (props: IProps) => {
  const {
    open,
    onDelete,
    onClose,
    onSave,
    onNoteChange,
    note,
    isNoteExists,
    index,
    examNote,
    additionalDesktopClassNames,
    noteLabel
  } = props;
  const classes = useStyles();

  const { blockClick, setBlockClick, setBlockClickCallback, setBlockActions } =
    useClickBlockerContext();
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState(false);

  const callbackLocked = () => setUnsavedChangesDialogOpen(true);

  const lockNavigation = callback => {
    setBlockClick(true);
    setBlockClickCallback(() => callback);
    setBlockActions(true);
  };

  const unlockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
    setBlockActions(false);
  };

  const handleUnsavedChangesSave = () => {
    unlockNavigation();
    setUnsavedChangesDialogOpen(false);
    onSave();
  };

  const handleChangeNote = e => {
    if (!blockClick && (e.trim().length || note.trim().length)) {
      lockNavigation(callbackLocked);
    }
    if (examNote?.body === e.trim() || (!examNote && !e.trim().length)) {
      unlockNavigation();
    }

    onNoteChange(e);
  };

  const handleClose = () => {
    unlockNavigation();
    setUnsavedChangesDialogOpen(false);
    onClose();
  };

  const handleSave = () => {
    unlockNavigation();
    onSave();
  };

  const handleDelete = e => {
    unlockNavigation();
    onDelete(e);
  };

  const unsavedChangesDialogContentRender = () => <>{UNSAVED_CHANGES_TEXT}</>;

  return (
    <>
      {open && (
        <div className={classes.dialogPaper}>
          <div className={classes.desktopHeader}>
            {isNoteExists ? `Edit ${noteLabel}` : `Add ${noteLabel}`}
          </div>
          <Textarea
            className={clsx(
              classes.textarea,
              { [classes.rowOdd]: index % 2 },
              additionalDesktopClassNames
            )}
            maxLength={90}
            label={format(new Date(), "MM/dd-yyyy - hh:mm aa")}
            placeholder={"Add Note"}
            rows={4}
            value={note}
            defaultValue={examNote?.body}
            onChange={handleChangeNote}
            classNameCounter={clsx(
              classes.textarea,
              { [classes.rowOdd]: index % 2 },
              additionalDesktopClassNames
            )}
          />
          <DialogActions className={classes.dialogActions}>
            {examNote?.id && (
              <Button className={classes.buttonDelete} onClick={handleDelete}>
                DELETE
              </Button>
            )}
            <div>
              <Button className={classes.buttonCancel} onClick={handleClose}>
                CANCEL
              </Button>
              <Button
                className={classes.buttonSave}
                disabled={!note.trim().length}
                onClick={handleSave}
              >
                SAVE
              </Button>
            </div>
          </DialogActions>
        </div>
      )}
      <UnsavedChangesDialog
        open={unsavedChangesDialogOpen}
        onDiscard={handleClose}
        onSave={handleUnsavedChangesSave}
        onCancel={() => {
          setUnsavedChangesDialogOpen(false);
        }}
        content={unsavedChangesDialogContentRender()}
      />
    </>
  );
};

export default ContentDesktop;
