import { IRoute } from "utils/routes";
import routes from "common/routes";
import { EPreRacesTableTab, IPreRaceTableTab } from "./types";
import { racehorse360 } from "@tsg/1st-grpc-web";

export const INFINITE_LIST_UPCOMING_RACE_ENTRIES_QUERY_KEY =
  "infinite-list-upcoming-race-entries";

export const preRacesTableTabs: IPreRaceTableTab[] = [
  {
    name: EPreRacesTableTab.ENTRIES,
    value: EPreRacesTableTab.ENTRIES,
    path: routes.preRaceScreeningEntries.path,
    entryStatuses: [
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_ENTRY_SUBMITTED
    ],
    itemsPerPage: 15,
    additionalRequestedFields: [
      "flagPriority",
      "horse.hasActualFlagOnTrack",
      "horse.flagOnTrack.flagOnTrackComment",
      "horse.riskFactors",
      "horse.riskFactorsCount",
      "horse.activeVetListsCount",
      "horse.last_past_performances",
      "horse.lifetime",
      "horse.lastCompletedExamWithNote.note",
      "horse.lastCompletedExamWithNote.completedDate",
      "horse.lastCompletedExamWithNote.assignedUser",
      "entryExamNote.body",
      "entryExamNote.notedBy",
      "entryExamNote.notedOn"
    ]
  },
  {
    name: EPreRacesTableTab.ENTRY_EXAMS,
    value: EPreRacesTableTab.ENTRY_EXAMS,
    path: routes.preRaceScreeningEntryExams.path,
    entryStatuses: [
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_ENTRY_EXAM_PENDING
    ],
    itemsPerPage: 20,
    additionalRequestedFields: [
      "flagPriority",
      "horse.riskFactors",
      "horse.riskFactorsCount",
      "entryExamNote.body",
      "entryExamNote.notedBy",
      "entryExamNote.notedOn",
      "facility.id",
      "entryExam.id",
      "entryExam.status"
    ]
  },
  {
    name: EPreRacesTableTab.RESULTS,
    value: EPreRacesTableTab.RESULTS,
    path: routes.preRaceScreeningResults.path,
    entryStatuses: [
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_ENTRY_EXAM_COMPLETED
    ],
    itemsPerPage: 20,
    additionalRequestedFields: ["entryExam.result", "entryExam.type"]
  },
  {
    name: EPreRacesTableTab.RACE_DAY,
    value: EPreRacesTableTab.RACE_DAY,
    path: routes.preRaceScreeningRaceDay.path,
    entryStatuses: [
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_ENTRY_SUBMITTED,
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_ENTRY_EXAM_PENDING,
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_ENTRY_EXAM_COMPLETED,
      racehorse360.UpcomingRaceEntryStatus
        .UPCOMING_RACE_ENTRY_STATUS_RACE_DAY_EXAM_PENDING
    ],
    itemsPerPage: 20,
    additionalRequestedFields: [
      "flagPriority",
      "horse.riskFactors",
      "horse.riskFactorsCount",
      "horse.activeVetListsCount",
      "entryExamNote.body",
      "entryExamNote.notedBy",
      "entryExamNote.notedOn",
      "facility.id",
      "raceDayExam.id",
      "raceDayExam.status",
      "entryExam.result",
      "entryExam.type"
    ]
  }
];

export const possiblePreRaceScreeningRoutes: IRoute[] = [
  routes.preRaceScreeningEntries,
  routes.preRaceScreeningEntryExams,
  routes.preRaceScreeningResults,
  routes.preRaceScreeningRaceDay
];

export const commonRequestedFields: string[] = [
  "raceDate",
  "highSpeedFurlongs",
  "horse.name",
  "horse.barn.name",
  "horse.birthday",
  "horse.gender",
  "horse.color",
  "horse.healthWatchStatus",
  "horse.lastWorkoutDate",
  "horse.lastRaceDate",
  "horse.trainer.firstName",
  "horse.trainer.middleName",
  "horse.trainer.lastName",
  "facility.timezone"
];
